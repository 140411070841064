@import 'src/scss/variable_color.scss';

* {
    box-sizing: border-box;
}

.p-component {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    font-size: 1rem;
    font-weight: normal;
}

.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    transition-duration: 0.2s;
}

.p-disabled,
.p-component:disabled {
    opacity: 0.6;
}

.p-error {
    color: $error-500;
}

.p-text-secondary {
    color: $grey-color;
}

.pi {
    font-size: 1rem;
}

.p-link {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    border-radius: 3px;
}

.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
    from {
        background-color: transparent;
    }

    to {
        background-color: var($maskbg);
    }
}

@keyframes p-component-overlay-leave-animation {
    from {
        background-color: var($maskbg);
    }

    to {
        background-color: transparent;
    }
}

.p-autocomplete .p-autocomplete-loader {
    right: 0.5rem;
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 2.857rem;
}

.p-autocomplete .p-autocomplete-multiple-container {
    padding: 0.25rem 0.5rem;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
    border-color: $secondary-button-500;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
    border-color: $secondary-button-500;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0.25rem 0;
}

.p-autocomplete
    .p-autocomplete-multiple-container
    .p-autocomplete-input-token
    input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    font-size: 1rem;
    color: $text-color-secondary;
    padding: 0;
    margin: 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #e3f2fd;
    color: $text-color-secondary;
    border-radius: 3px;
}

.p-autocomplete
    .p-autocomplete-multiple-container
    .p-autocomplete-token
    .p-autocomplete-token-icon {
    margin-left: 0.5rem;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    border-color: $error-500;
}

.p-autocomplete-panel {
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-autocomplete-panel .p-autocomplete-items:not(.p-autocomplete-virtualscroll) {
    padding: 0.5rem 0;
}

.p-autocomplete-panel
    .p-autocomplete-items.p-autocomplete-virtualscroll
    .cdk-virtual-scroll-content-wrapper {
    padding: 0.5rem 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: $text-color-secondary;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    color: $text-color-secondary;
    background: #e9ecef;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: $text-color-secondary;
    background: #e3f2fd;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
    padding: 0.5rem 1rem;
    color: $text-color-secondary;
    background: transparent;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    background: $color-white;
    font-weight: 600;
}

p-autocomplete.p-autocomplete-clearable .p-inputtext {
    padding-right: 2rem;
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
    color: $grey-color;
    right: 0.5rem;
}

p-autocomplete.p-autocomplete-clearable.p-autocomplete-dd
    .p-autocomplete-clear-icon {
    color: $grey-color;
    right: 2.857rem;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
    border-color: $error-500;
}

.p-datepicker {
    padding: 0.5rem;
    background: $color-white;
    color: $text-color-secondary;
    border: 1px solid #ced4da;
    border-radius: 3px;
}

.p-datepicker:not(.p-datepicker-inline) {
    background: $color-white;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: $color-white;
}

.p-datepicker .p-datepicker-header {
    padding: 0.5rem;
    color: $text-color-secondary;
    background: $color-white;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid $border-surface-color;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: $text-color-secondary;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    font-weight: 600;
    padding: 0.5rem;
}

.p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-year:enabled:hover,
.p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-month:enabled:hover {
    color: $secondary-button-500;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem;
}

.p-datepicker table {
    font-size: 1rem;
    margin: 0.5rem 0;
}

.p-datepicker table th {
    padding: 0.5rem;
}

.p-datepicker table th > span {
    width: 2.5rem;
    height: 2.5rem;
}

.p-datepicker table td {
    padding: 0.5rem;
}

.p-datepicker table td > span {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: box-shadow 0.2s;
    border: 1px solid transparent;
}

.p-datepicker table td > span.p-highlight {
    color: $text-color-secondary;
    background: #e3f2fd;
}

.p-datepicker table td > span:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-datepicker table td.p-datepicker-today > span {
    background: #ced4da;
    color: $text-color-secondary;
    border-color: transparent;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: $text-color-secondary;
    background: #e3f2fd;
}

.p-datepicker .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid $border-surface-color;
}

.p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
}

.p-datepicker .p-timepicker {
    border-top: 1px solid $border-surface-color;
    padding: 0.5rem;
}

.p-datepicker .p-timepicker button {
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-timepicker button:enabled:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.p-datepicker .p-timepicker button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-datepicker .p-timepicker button:last-child {
    margin-top: 0.2em;
}

.p-datepicker .p-timepicker span {
    font-size: 1.25rem;
}

.p-datepicker .p-timepicker > div {
    padding: 0 0.5rem;
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
}

.p-datepicker .p-monthpicker {
    margin: 0.5rem 0;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.5rem;
    transition: box-shadow 0.2s;
    border-radius: 3px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: $text-color-secondary;
    background: #e3f2fd;
}

.p-datepicker .p-yearpicker {
    margin: 0.5rem 0;
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
    padding: 0.5rem;
    transition: box-shadow 0.2s;
    border-radius: 3px;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: $text-color-secondary;
    background: #e3f2fd;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-left: 1px solid $border-surface-color;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
    border-left: 0 none;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
}

.p-datepicker:not(.p-disabled)
    table
    td
    span:not(.p-highlight):not(.p-disabled):hover {
    background: #e9ecef;
}

.p-datepicker:not(.p-disabled)
    table
    td
    span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-datepicker:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
}

.p-datepicker:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-datepicker:not(.p-disabled)
    .p-yearpicker
    .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
}

.p-datepicker:not(.p-disabled)
    .p-yearpicker
    .p-yearpicker-year:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

p-calendar.p-calendar-clearable .p-inputtext {
    padding-right: 2rem;
}

p-calendar.p-calendar-clearable .p-calendar-clear-icon {
    color: $grey-color;
    right: 0.5rem;
}

p-calendar.p-calendar-clearable.p-calendar-w-btn .p-calendar-clear-icon {
    color: $grey-color;
    right: 2.857rem;
}

@media screen and (max-width: 769px) {
    .p-datepicker table th,
    .p-datepicker table td {
        padding: 0;
    }
}

.p-cascadeselect {
    background: $color-white;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 3px;
}

.p-cascadeselect:not(.p-disabled):hover {
    border-color: $secondary-button-500;
}

.p-cascadeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
    border-color: $secondary-button-500;
}

.p-cascadeselect .p-cascadeselect-label {
    background: transparent;
    border: 0 none;
    padding: 0.5rem 0.5rem;
}

.p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: $grey-color;
}

.p-cascadeselect .p-cascadeselect-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
}

.p-cascadeselect .p-cascadeselect-trigger {
    background: transparent;
    color: $grey-color;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-cascadeselect-panel {
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-cascadeselect-panel .p-cascadeselect-items {
    padding: 0.5rem 0;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    margin: 0;
    border: 0 none;
    color: $text-color-secondary;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
}

.p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item
    .p-cascadeselect-item-content {
    padding: 0.5rem 1rem;
}

.p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item
    .p-cascadeselect-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item.p-highlight {
    color: $text-color-secondary;
    background: #e3f2fd;
}

.p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: $text-color-secondary;
    background: #e9ecef;
}

.p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item
    .p-cascadeselect-group-icon {
    font-size: 0.875rem;
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
    border-color: $error-500;
}

.p-input-filled .p-cascadeselect {
    background: #f8f9fa;
}

.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: #f8f9fa;
}

.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
    background-color: $color-white;
}

p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
    padding-right: 0.5rem;
}

p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
    color: $grey-color;
    right: 2.357rem;
}

.p-checkbox {
    width: 20px;
    height: 20px;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #ced4da;
    background: $color-white;
    width: 20px;
    height: 20px;
    color: $text-color-secondary;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: $color-white;
    font-size: 14px;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: $secondary-button-500;
    background: $secondary-button-500;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: $secondary-button-500;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
    border-color: $secondary-button-500;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: $secondary-button-700;
    background: $secondary-button-700;
    color: $color-white;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    border-color: $error-500;
}

.p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #f8f9fa;
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: $secondary-button-500;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #f8f9fa;
}

.p-input-filled
    .p-checkbox:not(.p-checkbox-disabled)
    .p-checkbox-box.p-highlight:hover {
    background: $secondary-button-700;
}

.p-checkbox-label {
    margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container {
    padding: 0.25rem 0.5rem;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: $secondary-button-500;
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
    border-color: $secondary-button-500;
}

.p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #e3f2fd;
    color: $text-color-secondary;
    border-radius: 3px;
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0.25rem 0;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    font-size: 1rem;
    color: $text-color-secondary;
    padding: 0;
    margin: 0;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
    border-color: $error-500;
}

p-chips.p-chips-clearable .p-inputtext {
    padding-right: 1.5rem;
}

p-chips.p-chips-clearable .p-chips-clear-icon {
    color: $grey-color;
    right: 0.5rem;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
    width: 2rem;
    height: 2rem;
}

.p-colorpicker-panel {
    background: #323232;
    border: 1px solid #191919;
}

.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: $color-white;
}

.p-colorpicker-overlay-panel {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-dropdown {
    background: $color-white;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 3px;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: $secondary-button-500;
}

.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
    border-color: $secondary-button-500;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.5rem;
}

.p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: $grey-color;
}

.p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
}

.p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: $grey-color;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-dropdown .p-dropdown-clear-icon {
    color: $grey-color;
    right: 2.357rem;
}

.p-dropdown-panel {
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-dropdown-panel .p-dropdown-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: $text-color-secondary;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.5rem;
    margin-right: -1.5rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.5rem;
    color: $grey-color;
}

.p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll) {
    padding: 0.5rem 0;
}

.p-dropdown-panel
    .p-dropdown-items.p-dropdown-virtualscroll
    .cdk-virtual-scroll-content-wrapper {
    padding: 0.5rem 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: $text-color-secondary;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: $text-color-secondary;
    background: #e3f2fd;
}

.p-dropdown-panel
    .p-dropdown-items
    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: $text-color-secondary;
    background: #e9ecef;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.5rem 1rem;
    color: $text-color-secondary;
    background: transparent;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    background: $color-white;
    font-weight: 600;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
    border-color: $error-500;
}

.p-input-filled .p-dropdown {
    background: #f8f9fa;
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #f8f9fa;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: $color-white;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
    background-color: transparent;
}

.p-editor-container .p-editor-toolbar {
    background: #f8f9fa;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid $border-surface-color;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
    stroke: $grey-color;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
    fill: $grey-color;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
    border: 0 none;
    color: $grey-color;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker
    .ql-picker-label:hover {
    color: $text-color-secondary;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker
    .ql-picker-label:hover
    .ql-stroke {
    stroke: $text-color-secondary;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker
    .ql-picker-label:hover
    .ql-fill {
    fill: $text-color-secondary;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-label {
    color: $text-color-secondary;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-label
    .ql-stroke {
    stroke: $text-color-secondary;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-label
    .ql-fill {
    fill: $text-color-secondary;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-options {
    background: $color-white;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    padding: 0.5rem 0;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-options
    .ql-picker-item {
    color: $text-color-secondary;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-options
    .ql-picker-item:hover {
    color: $text-color-secondary;
    background: #e9ecef;
}

.p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded:not(.ql-icon-picker)
    .ql-picker-item {
    padding: 0.5rem 1rem;
}

.p-editor-container .p-editor-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-editor-container .p-editor-content.ql-snow {
    border: 1px solid $border-surface-color;
}

.p-editor-container .p-editor-content .ql-editor {
    background: $color-white;
    color: $text-color-secondary;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
    color: $text-color-secondary;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: $text-color-secondary;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: $text-color-secondary;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: $secondary-button-500;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: $secondary-button-500;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: $secondary-button-500;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container
    .ql-snow.ql-toolbar
    .ql-picker-label.ql-active
    .ql-picker-label,
.p-editor-container
    .ql-snow.ql-toolbar
    .ql-picker-item.ql-selected
    .ql-picker-label {
    color: $secondary-button-500;
}

.p-inputgroup-addon {
    background: #e9ecef;
    color: $grey-color;
    border-top: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    padding: 0.5rem 0.5rem;
    min-width: 2.357rem;
}

.p-inputgroup-addon:last-child {
    border-right: 1px solid #ced4da;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-element,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
}

.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-element + .p-inputgroup-addon,
.p-inputgroup
    > .p-inputwrapper
    > .p-component
    > .p-inputtext
    + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none;
}

.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-element:focus,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
    z-index: 1;
}

.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-element:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
    z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-fluid .p-inputgroup .p-button {
    width: auto;
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.357rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
    border-color: $error-500;
}

p-inputmask.p-inputmask-clearable .p-inputtext {
    padding-right: 2rem;
}

p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
    color: $grey-color;
    right: 0.5rem;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
    border-color: $error-500;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
    padding-right: 2rem;
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
    color: $grey-color;
    right: 0.5rem;
}

p-inputnumber.p-inputnumber-clearable
    .p-inputnumber-buttons-stacked
    .p-inputnumber-clear-icon {
    right: 2.857rem;
}

p-inputnumber.p-inputnumber-clearable
    .p-inputnumber-buttons-horizontal
    .p-inputnumber-clear-icon {
    right: 2.857rem;
}

.p-inputswitch {
    width: 3rem;
    height: 1.75rem;
}

.p-inputswitch .p-inputswitch-slider {
    background: #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 30px;
}

.p-inputswitch .p-inputswitch-slider:before {
    background: $color-white;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.25rem;
    margin-top: -0.625rem;
    border-radius: 50%;
    transition-duration: 0.2s;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #b6bfc8;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: $secondary-button-500;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: $color-white;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
    .p-inputswitch-slider {
    background: $secondary-button-500;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
    border-color: $error-500;
}

.p-inputtext {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    font-size: 1rem;
    color: $text-color-secondary;
    background: $color-white;
    padding: 0.5rem 0.5rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    appearance: none;
    border-radius: 3px;
}

.p-inputtext:enabled:hover {
    border-color: $secondary-button-500;
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
    border-color: $secondary-button-500;
}

.p-inputtext.ng-dirty.ng-invalid {
    border-color: $error-500;
}

.p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
}

.p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 0.625rem 0.625rem;
}

.p-float-label > label {
    left: 0.5rem;
    color: $grey-color;
    transition-duration: 0.2s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
    color: $error-500;
}

.p-input-icon-left > i:first-of-type {
    left: 0.5rem;
    color: $grey-color;
}

.p-input-icon-left > .p-inputtext {
    padding-left: 2rem;
}

.p-input-icon-left.p-float-label > label {
    left: 2rem;
}

.p-input-icon-right > i:last-of-type {
    right: 0.5rem;
    color: $grey-color;
}

.p-input-icon-right > .p-inputtext {
    padding-right: 2rem;
}

::-webkit-input-placeholder {
    color: $grey-color;
}

:-moz-placeholder {
    color: $grey-color;
}

::-moz-placeholder {
    color: $grey-color;
}

:-ms-input-placeholder {
    color: $grey-color;
}

.p-input-filled .p-inputtext {
    background-color: #f8f9fa;
}

.p-input-filled .p-inputtext:enabled:hover {
    background-color: #f8f9fa;
}

.p-input-filled .p-inputtext:enabled:focus {
    background-color: $color-white;
}

.p-inputtext-sm .p-inputtext {
    font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
}

.p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: 0.625rem 0.625rem;
}

.p-listbox {
    background: $color-white;
    color: $text-color-secondary;
    border: 1px solid #ced4da;
    border-radius: 3px;
}

.p-listbox .p-listbox-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: $text-color-secondary;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.5rem;
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
    right: 0.5rem;
    color: $grey-color;
}

.p-listbox .p-listbox-header .p-checkbox {
    margin-right: 0.5rem;
}

.p-listbox .p-listbox-list {
    padding: 0.5rem 0;
}

.p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: $text-color-secondary;
    transition: box-shadow 0.2s;
    border-radius: 0;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: $text-color-secondary;
    background: #e3f2fd;
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
    margin-right: 0.5rem;
}

.p-listbox .p-listbox-list .p-listbox-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    background: $color-white;
    font-weight: 600;
}

.p-listbox .p-listbox-list .p-listbox-empty-message {
    padding: 0.5rem 1rem;
    color: $text-color-secondary;
    background: transparent;
}

.p-listbox:not(.p-disabled)
    .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: $text-color-secondary;
    background: #e9ecef;
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
    border-color: $error-500;
}

.p-multiselect {
    background: $color-white;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 3px;
}

.p-multiselect:not(.p-disabled):hover {
    border-color: $secondary-button-500;
}

.p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
    border-color: $secondary-button-500;
}

.p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-multiselect .p-multiselect-label.p-placeholder {
    color: $grey-color;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #e3f2fd;
    color: $text-color-secondary;
    border-radius: 3px;
}

.p-multiselect.p-multiselect-chip
    .p-multiselect-token
    .p-multiselect-token-icon {
    margin-left: 0.5rem;
}

.p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: $grey-color;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.25rem 0.5rem;
}

.p-multiselect-panel {
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-multiselect-panel .p-multiselect-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: $text-color-secondary;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 350px;
    flex-wrap: wrap;
}

.p-multiselect-panel
    .p-multiselect-header
    .p-multiselect-filter-container
    .p-inputtext {
    padding-right: 1.5rem;
}

.p-multiselect-panel
    .p-multiselect-header
    .p-multiselect-filter-container
    .p-multiselect-filter-icon {
    right: 0.5rem;
    color: $grey-color;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-left: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-multiselect-panel .p-multiselect-items:not(.p-multiselect-virtualscroll) {
    padding: 0.5rem 0;
}

.p-multiselect-panel
    .p-multiselect-items.p-multiselect-virtualscroll
    .cdk-virtual-scroll-content-wrapper {
    padding: 0.5rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: $text-color-secondary;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: $text-color-secondary;
    background: #e3f2fd;
}

.p-multiselect-panel
    .p-multiselect-items
    .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: $text-color-secondary;
    background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    background: $color-white;
    font-weight: 600;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: 0.5rem 1rem;
    color: $text-color-secondary;
    background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
    border-color: $error-500;
}

.p-input-filled .p-multiselect {
    background: #f8f9fa;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #f8f9fa;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: $color-white;
}

p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
    padding-right: 1.5rem;
}

p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
    color: $grey-color;
    right: 2.357rem;
}

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
    border-color: $error-500;
}

.p-password-panel {
    padding: 1rem;
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}

.p-password-panel .p-password-meter {
    margin-bottom: 0.5rem;
    background: $border-surface-color;
}

.p-password-panel .p-password-meter .p-password-strength.weak {
    background: $error-500;
}

.p-password-panel .p-password-meter .p-password-strength.medium {
    background: $information-500;
}

.p-password-panel .p-password-meter .p-password-strength.strong {
    background: $primary-button-500;
}

p-password.p-password-clearable .p-password-input {
    padding-right: 2rem;
}

p-password.p-password-clearable .p-password-clear-icon {
    color: $grey-color;
    right: 0.5rem;
}

p-password.p-password-clearable.p-password-mask .p-password-input {
    padding-right: 3.5rem;
}

p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
    color: $grey-color;
    right: 2rem;
}

.p-radiobutton {
    width: 20px;
    height: 20px;
}

.p-radiobutton .p-radiobutton-box {
    border: 2px solid #ced4da;
    background: $color-white;
    width: 20px;
    height: 20px;
    color: $text-color-secondary;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: $secondary-button-500;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
    border-color: $secondary-button-500;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 12px;
    height: 12px;
    transition-duration: 0.2s;
    background-color: $color-white;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $secondary-button-500;
    background: $secondary-button-500;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: $secondary-button-700;
    background: $secondary-button-700;
    color: $color-white;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    border-color: $error-500;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
    background-color: #f8f9fa;
}

.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #f8f9fa;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: $secondary-button-500;
}

.p-input-filled
    .p-radiobutton
    .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: $secondary-button-700;
}

.p-radiobutton-label {
    margin-left: 0.5rem;
}

.p-rating .p-rating-icon {
    color: $text-color-secondary;
    margin-left: 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    font-size: 1.143rem;
}

.p-rating .p-rating-icon.p-rating-cancel {
    color: $error-500;
}

.p-rating .p-rating-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-rating .p-rating-icon:first-child {
    margin-left: 0;
}

.p-rating .p-rating-icon.pi-star-fill {
    color: $secondary-button-500;
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
    color: $secondary-button-500;
}

.p-rating:not(.p-disabled):not(.p-readonly)
    .p-rating-icon.p-rating-cancel:hover {
    color: $error-700;
}

.p-selectbutton .p-button {
    background: $color-white;
    border: 1px solid #ced4da;
    color: $text-color-secondary;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
    color: $grey-color;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
    border-color: #ced4da;
    color: $text-color-secondary;
}

.p-selectbutton
    .p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-left,
.p-selectbutton
    .p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-right {
    color: $grey-color;
}

.p-selectbutton .p-button.p-highlight {
    background: $secondary-button-500;
    border-color: $secondary-button-500;
    color: $color-white;
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: $color-white;
}

.p-selectbutton .p-button.p-highlight:hover {
    background: $secondary-button-700;
    border-color: $secondary-button-700;
    color: $color-white;
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: $color-white;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    border-color: $error-500;
}

.p-slider {
    background: $border-surface-color;
    border: 0 none;
    border-radius: 3px;
}

.p-slider.p-slider-horizontal {
    height: 0.286rem;
}

.p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -0.5715rem;
    margin-left: -0.5715rem;
}

.p-slider.p-slider-vertical {
    width: 0.286rem;
}

.p-slider.p-slider-vertical .p-slider-handle {
    margin-left: -0.5715rem;
    margin-bottom: -0.5715rem;
}

.p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: $color-white;
    border: 2px solid $secondary-button-500;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-slider .p-slider-handle:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-slider .p-slider-range {
    background: $secondary-button-500;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: $secondary-button-500;
    border-color: $secondary-button-500;
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s, left 0.2s;
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
    transition: width 0.2s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s, bottom 0.2s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
    transition: height 0.2s;
}

.p-togglebutton.p-button {
    background: $color-white;
    border: 1px solid #ced4da;
    color: $text-color-secondary;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
    color: $grey-color;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
    border-color: #ced4da;
    color: $text-color-secondary;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-right {
    color: $grey-color;
}

.p-togglebutton.p-button.p-highlight {
    background: $secondary-button-500;
    border-color: $secondary-button-500;
    color: $color-white;
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: $color-white;
}

.p-togglebutton.p-button.p-highlight:hover {
    background: $secondary-button-700;
    border-color: $secondary-button-700;
    color: $color-white;
}

.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: $color-white;
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
    border-color: $error-500;
}

.p-treeselect {
    background: $color-white;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 3px;
}

.p-treeselect:not(.p-disabled):hover {
    border-color: $secondary-button-700;
}

.p-treeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
    border-color: $secondary-button-500;
}

.p-treeselect .p-treeselect-label {
    padding: 0.5rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-treeselect .p-treeselect-label.p-placeholder {
    color: $grey-color;
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: $border-surface-color;
    color: $text-color-secondary;
    border-radius: 16px;
}

.p-treeselect .p-treeselect-trigger {
    background: transparent;
    color: $grey-color;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
    border-color: $error-500;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: 0.25rem 0.5rem;
}

.p-treeselect-panel {
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-treeselect-panel .p-treeselect-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: $text-color-secondary;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
    margin-right: 0.5rem;
}

.p-treeselect-panel
    .p-treeselect-header
    .p-treeselect-filter-container
    .p-treeselect-filter {
    padding-right: 1.5rem;
}

.p-treeselect-panel
    .p-treeselect-header
    .p-treeselect-filter-container
    .p-treeselect-filter-icon {
    right: 0.5rem;
    color: $grey-color;
}

.p-treeselect-panel
    .p-treeselect-header
    .p-treeselect-filter-container.p-treeselect-clearable-filter
    .p-treeselect-filter {
    padding-right: 3rem;
}

.p-treeselect-panel
    .p-treeselect-header
    .p-treeselect-filter-container.p-treeselect-clearable-filter
    .p-treeselect-filter-clear-icon {
    right: 2rem;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
    border: 0 none;
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
    padding: 0.5rem 1rem;
    color: $text-color-secondary;
    background: transparent;
}

.p-input-filled .p-treeselect {
    background: #f8f9fa;
}

.p-input-filled .p-treeselect:not(.p-disabled):hover {
    background-color: #f8f9fa;
}

.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
    background-color: $color-white;
}

p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
    padding-right: 1.5rem;
}

p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
    color: $grey-color;
    right: 2.357rem;
}

.p-button {
    color: $color-white;
    background: $secondary-button-500;
    border: 1px solid $secondary-button-500;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 3px;
}

.p-button:enabled:hover {
    background: $secondary-button-700;
    color: $color-white;
    border-color: $secondary-button-700;
}

.p-button:enabled:active {
    background: $secondary-button-500;
    color: $color-white;
    border-color: $secondary-button-500;
}

.p-button.p-button-outlined {
    background-color: transparent;
    color: $secondary-button-500;
    border: 1px solid;
}

.p-button.p-button-outlined:enabled:hover {
    background: rgba(33, 150, 243, 0.04);
    color: $secondary-button-700;
    border: 1px solid;
}

.p-button.p-button-outlined:enabled:active {
    background: rgba(33, 150, 243, 0.16);
    color: $secondary-button-500;
    border: 1px solid;
}

.p-button.p-button-outlined.p-button-plain {
    color: $grey-color;
    border-color: $grey-color;
}

.p-button.p-button-outlined.p-button-plain:enabled:hover {
    background: #e9ecef;
    color: $grey-color;
}

.p-button.p-button-outlined.p-button-plain:enabled:active {
    background: $border-surface-color;
    color: $grey-color;
}

.p-button.p-button-text {
    background-color: transparent;
    color: $secondary-button-500;
    border-color: transparent;
}

.p-button.p-button-text:enabled:hover {
    background: rgba(33, 150, 243, 0.04);
    color: $secondary-button-500;
    border-color: transparent;
}

.p-button.p-button-text:enabled:active {
    background: rgba(33, 150, 243, 0.16);
    color: $secondary-button-500;
    border-color: transparent;
}

.p-button.p-button-text.p-button-plain {
    color: $grey-color;
}

.p-button.p-button-text.p-button-plain:enabled:hover {
    background: #e9ecef;
    color: $grey-color;
}

.p-button.p-button-text.p-button-plain:enabled:active {
    background: $border-surface-color;
    color: $grey-color;
}

.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-button .p-button-icon-left {
    margin-right: 0.5rem;
}

.p-button .p-button-icon-right {
    margin-left: 0.5rem;
}

.p-button .p-button-icon-bottom {
    margin-top: 0.5rem;
}

.p-button .p-button-icon-top {
    margin-bottom: 0.5rem;
}

.p-button .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: $secondary-button-500;
    background-color: $color-white;
}

.p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-button.p-button-rounded {
    border-radius: 2rem;
}

.p-button.p-button-icon-only {
    width: 2.357rem;
    padding: 0.5rem 0;
}

.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
    margin: 0;
}

.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.357rem;
}

.p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
}

.p-button.p-button-sm .p-button-icon {
    font-size: 0.875rem;
}

.p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.625rem 1.25rem;
}

.p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem;
}

.p-button.p-button-loading-label-only .p-button-label {
    margin-left: 0.5rem;
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
    margin-right: 0;
}

.p-fluid .p-button {
    width: 100%;
}

.p-fluid .p-button-icon-only {
    width: 2.357rem;
}

.p-fluid .p-buttonset {
    display: flex;
}

.p-fluid .p-buttonset .p-button {
    flex: 1;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
    color: $color-white;
    background: #607d8b;
    border: 1px solid #607d8b;
}

.p-button.p-button-secondary:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover {
    background: #56717d;
    color: $color-white;
    border-color: #56717d;
}

.p-button.p-button-secondary:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #beccd2;
}

.p-button.p-button-secondary:enabled:active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active {
    background: #4d646f;
    color: $color-white;
    border-color: #4d646f;
}

.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    background-color: transparent;
    color: #607d8b;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
    background: rgba(96, 125, 139, 0.04);
    color: #607d8b;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
    background: rgba(96, 125, 139, 0.16);
    color: #607d8b;
    border: 1px solid;
}

.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text {
    background-color: transparent;
    color: #607d8b;
    border-color: transparent;
}

.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
    background: rgba(96, 125, 139, 0.04);
    border-color: transparent;
    color: #607d8b;
}

.p-button.p-button-secondary.p-button-text:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
    background: rgba(96, 125, 139, 0.16);
    border-color: transparent;
    color: #607d8b;
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
    color: $color-white;
    background: $secondary-button-500;
    border: 1px solid $secondary-button-500;
}

.p-button.p-button-info:enabled:hover,
.p-buttonset.p-button-info > .p-button:enabled:hover,
.p-splitbutton.p-button-info > .p-button:enabled:hover {
    background: $secondary-button-700;
    color: $color-white;
    border-color: $secondary-button-700;
}

.p-button.p-button-info:enabled:focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-button.p-button-info:enabled:active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info > .p-button:enabled:active {
    background: $secondary-button-500;
    color: $color-white;
    border-color: $secondary-button-500;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined {
    background-color: transparent;
    color: $secondary-button-500;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
    background: rgba(2, 136, 209, 0.04);
    color: $secondary-button-700;
    border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    color: $secondary-button-500;
    border: 1px solid;
}

.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info > .p-button.p-button-text,
.p-splitbutton.p-button-info > .p-button.p-button-text {
    background-color: transparent;
    color: $secondary-button-500;
    border-color: transparent;
}

.p-button.p-button-info.p-button-text:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
    background: rgba(2, 136, 209, 0.04);
    border-color: transparent;
    color: $secondary-button-700;
}

.p-button.p-button-info.p-button-text:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
    background: rgba(2, 136, 209, 0.16);
    border-color: transparent;
    color: $secondary-button-500;
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
    color: $color-white;
    background: $primary-button-500;
    border: 1px solid $primary-button-500;
}

.p-button.p-button-success:enabled:hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover {
    background: $primary-button-700;
    color: $color-white;
    border-color: $primary-button-700;
}

.p-button.p-button-success:enabled:focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem $primary-button-500;
}

.p-button.p-button-success:enabled:active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:enabled:active {
    background: $primary-button-500;
    color: $color-white;
    border-color: $primary-button-500;
}

.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined {
    background-color: transparent;
    color: $primary-button-500;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
    background: rgba(104, 159, 56, 0.04);
    color: $primary-button-700;
    border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
    background: rgba(104, 159, 56, 0.16);
    color: $primary-button-500;
    border: 1px solid;
}

.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text {
    background-color: transparent;
    color: $primary-button-500;
    border-color: transparent;
}

.p-button.p-button-success.p-button-text:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
    background: rgba(104, 159, 56, 0.04);
    border-color: transparent;
    color: $primary-button-700;
}

.p-button.p-button-success.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
    background: rgba(104, 159, 56, 0.16);
    border-color: transparent;
    color: $primary-button-500;
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
    color: #212529;
    background: $information-500;
    border: 1px solid $information-500;
}

.p-button.p-button-warning:enabled:hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover {
    background: $information-700;
    color: #212529;
    border-color: $information-700;
}

.p-button.p-button-warning:enabled:focus,
.p-buttonset.p-button-warning > .p-button:enabled:focus,
.p-splitbutton.p-button-warning > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #fde6ab;
}

.p-button.p-button-warning:enabled:active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning > .p-button:enabled:active {
    background: $information-500;
    color: #212529;
    border-color: $information-500;
}

.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning > .p-button.p-button-outlined,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    background-color: transparent;
    color: $information-500;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
    background: rgba(251, 192, 45, 0.04);
    color: $information-500;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
    background: rgba(251, 192, 45, 0.16);
    color: $information-500;
    border: 1px solid;
}

.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning > .p-button.p-button-text,
.p-splitbutton.p-button-warning > .p-button.p-button-text {
    background-color: transparent;
    color: $information-500;
    border-color: transparent;
}

.p-button.p-button-warning.p-button-text:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
    background: rgba(251, 192, 45, 0.04);
    border-color: transparent;
    color: $information-500;
}

.p-button.p-button-warning.p-button-text:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
    background: rgba(251, 192, 45, 0.16);
    border-color: transparent;
    color: $information-500;
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
    color: $color-white;
    background: #9c27b0;
    border: 1px solid #9c27b0;
}

.p-button.p-button-help:enabled:hover,
.p-buttonset.p-button-help > .p-button:enabled:hover,
.p-splitbutton.p-button-help > .p-button:enabled:hover {
    background: #8c239e;
    color: $color-white;
    border-color: #8c239e;
}

.p-button.p-button-help:enabled:focus,
.p-buttonset.p-button-help > .p-button:enabled:focus,
.p-splitbutton.p-button-help > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem #df9eea;
}

.p-button.p-button-help:enabled:active,
.p-buttonset.p-button-help > .p-button:enabled:active,
.p-splitbutton.p-button-help > .p-button:enabled:active {
    background: #7d1f8d;
    color: $color-white;
    border-color: #7d1f8d;
}

.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined {
    background-color: transparent;
    color: #9c27b0;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
    background: rgba(156, 39, 176, 0.04);
    color: #9c27b0;
    border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
    background: rgba(156, 39, 176, 0.16);
    color: #9c27b0;
    border: 1px solid;
}

.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help > .p-button.p-button-text,
.p-splitbutton.p-button-help > .p-button.p-button-text {
    background-color: transparent;
    color: #9c27b0;
    border-color: transparent;
}

.p-button.p-button-help.p-button-text:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
    background: rgba(156, 39, 176, 0.04);
    border-color: transparent;
    color: #9c27b0;
}

.p-button.p-button-help.p-button-text:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
    background: rgba(156, 39, 176, 0.16);
    border-color: transparent;
    color: #9c27b0;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
    color: $color-white;
    background: $error-500;
    border: 1px solid $error-500;
}

.p-button.p-button-danger:enabled:hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover {
    background: $error-700;
    color: $color-white;
    border-color: $error-700;
}

.p-button.p-button-danger:enabled:focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus {
    box-shadow: 0 0 0 0.2rem $error-050;
}

.p-button.p-button-danger:enabled:active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:enabled:active {
    background: $error-500;
    color: $color-white;
    border-color: $error-500;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: $error-500;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
    background: rgba(211, 47, 47, 0.04);
    color: $error-700;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
    background: rgba(211, 47, 47, 0.16);
    color: $error-500;
    border: 1px solid;
}

.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text {
    background-color: transparent;
    color: $error-500;
    border-color: transparent;
}

.p-button.p-button-danger.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
    background: rgba(211, 47, 47, 0.04);
    border-color: transparent;
    color: $error-700;
}

.p-button.p-button-danger.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
    background: rgba(211, 47, 47, 0.16);
    border-color: transparent;
    color: $error-500;
}

.p-button.p-button-link {
    color: $secondary-color-500;
    background: transparent;
    border: transparent;
}

.p-button.p-button-link:enabled:hover {
    background: transparent;
    color: $secondary-color-500;
    border-color: transparent;
}

.p-button.p-button-link:enabled:hover .p-button-label {
    text-decoration: underline;
}

.p-button.p-button-link:enabled:focus {
    background: transparent;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
    border-color: transparent;
}

.p-button.p-button-link:enabled:active {
    background: transparent;
    color: $secondary-color-500;
    border-color: transparent;
}

.p-speeddial-button.p-button.p-button-icon-only {
    width: 4rem;
    height: 4rem;
}

.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
    font-size: 1.3rem;
}

.p-speeddial-action {
    width: 3rem;
    height: 3rem;
    background: $text-color-secondary;
    color: #fff;
}

.p-speeddial-action:hover {
    background: #343a40;
    color: #fff;
}

.p-speeddial-direction-up .p-speeddial-item {
    margin: 0.25rem 0;
}

.p-speeddial-direction-up .p-speeddial-item:first-child {
    margin-bottom: 0.5rem;
}

.p-speeddial-direction-down .p-speeddial-item {
    margin: 0.25rem 0;
}

.p-speeddial-direction-down .p-speeddial-item:first-child {
    margin-top: 0.5rem;
}

.p-speeddial-direction-left .p-speeddial-item {
    margin: 0 0.25rem;
}

.p-speeddial-direction-left .p-speeddial-item:first-child {
    margin-right: 0.5rem;
}

.p-speeddial-direction-right .p-speeddial-item {
    margin: 0 0.25rem;
}

.p-speeddial-direction-right .p-speeddial-item:first-child {
    margin-left: 0.5rem;
}

.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
    margin: 0;
}

.p-speeddial-circle .p-speeddial-item:first-child,
.p-speeddial-circle .p-speeddial-item:last-child,
.p-speeddial-semi-circle .p-speeddial-item:first-child,
.p-speeddial-semi-circle .p-speeddial-item:last-child,
.p-speeddial-quarter-circle .p-speeddial-item:first-child,
.p-speeddial-quarter-circle .p-speeddial-item:last-child {
    margin: 0;
}

.p-speeddial-mask {
    background-color: rgba(0, 0, 0, 0.4);
}

.p-splitbutton {
    border-radius: 3px;
}

.p-splitbutton.p-button-outlined > .p-button {
    background-color: transparent;
    color: $secondary-button-500;
    border: 1px solid;
}

.p-splitbutton.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(33, 150, 243, 0.04);
    color: $secondary-button-500;
}

.p-splitbutton.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(33, 150, 243, 0.16);
    color: $secondary-button-500;
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button {
    color: $grey-color;
    border-color: $grey-color;
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-outlined.p-button-plain
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: #e9ecef;
    color: $grey-color;
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-outlined.p-button-plain
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: $border-surface-color;
    color: $grey-color;
}

.p-splitbutton.p-button-text > .p-button {
    background-color: transparent;
    color: $secondary-button-500;
    border-color: transparent;
}

.p-splitbutton.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(33, 150, 243, 0.04);
    color: $secondary-button-500;
    border-color: transparent;
}

.p-splitbutton.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(33, 150, 243, 0.16);
    color: $secondary-button-500;
    border-color: transparent;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button {
    color: $grey-color;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover,
.p-splitbutton.p-button-text.p-button-plain
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: #e9ecef;
    color: $grey-color;
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active,
.p-splitbutton.p-button-text.p-button-plain
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: $border-surface-color;
    color: $grey-color;
}

.p-splitbutton.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-splitbutton.p-button-rounded {
    border-radius: 2rem;
}

.p-splitbutton.p-button-rounded > .p-button {
    border-radius: 2rem;
}

.p-splitbutton.p-button-sm > .p-button {
    font-size: 0.875rem;
    padding: 0.4375rem 0.875rem;
}

.p-splitbutton.p-button-sm > .p-button .p-button-icon {
    font-size: 0.875rem;
}

.p-splitbutton.p-button-lg > .p-button {
    font-size: 1.25rem;
    padding: 0.625rem 1.25rem;
}

.p-splitbutton.p-button-lg > .p-button .p-button-icon {
    font-size: 1.25rem;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
    background-color: transparent;
    color: #607d8b;
    border: 1px solid;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(96, 125, 139, 0.04);
    color: #607d8b;
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(96, 125, 139, 0.16);
    color: #607d8b;
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button {
    background-color: transparent;
    color: #607d8b;
    border-color: transparent;
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(96, 125, 139, 0.04);
    border-color: transparent;
    color: #607d8b;
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-secondary.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(96, 125, 139, 0.16);
    border-color: transparent;
    color: #607d8b;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button {
    background-color: transparent;
    color: $secondary-button-500;
    border: 1px solid;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(2, 136, 209, 0.04);
    color: $secondary-button-500;
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(2, 136, 209, 0.16);
    color: $secondary-button-500;
}

.p-splitbutton.p-button-info.p-button-text > .p-button {
    background-color: transparent;
    color: $secondary-button-500;
    border-color: transparent;
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-info.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(2, 136, 209, 0.04);
    border-color: transparent;
    color: $secondary-button-500;
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-info.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(2, 136, 209, 0.16);
    border-color: transparent;
    color: $secondary-button-500;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button {
    background-color: transparent;
    color: $primary-button-500;
    border: 1px solid;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(104, 159, 56, 0.04);
    color: $primary-button-700;
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(104, 159, 56, 0.16);
    color: $primary-button-500;
}

.p-splitbutton.p-button-success.p-button-text > .p-button {
    background-color: transparent;
    color: $primary-button-500;
    border-color: transparent;
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-success.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(104, 159, 56, 0.04);
    border-color: transparent;
    color: $primary-button-700;
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-success.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(104, 159, 56, 0.16);
    border-color: transparent;
    color: $primary-button-500;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button {
    background-color: transparent;
    color: $information-500;
    border: 1px solid;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(251, 192, 45, 0.04);
    color: $information-500;
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(251, 192, 45, 0.16);
    color: $information-500;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button {
    background-color: transparent;
    color: $information-500;
    border-color: transparent;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-warning.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(251, 192, 45, 0.04);
    border-color: transparent;
    color: $information-500;
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-warning.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(251, 192, 45, 0.16);
    border-color: transparent;
    color: $information-500;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button {
    background-color: transparent;
    color: #9c27b0;
    border: 1px solid;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(156, 39, 176, 0.04);
    color: #9c27b0;
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(156, 39, 176, 0.16);
    color: #9c27b0;
}

.p-splitbutton.p-button-help.p-button-text > .p-button {
    background-color: transparent;
    color: #9c27b0;
    border-color: transparent;
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-help.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(156, 39, 176, 0.04);
    border-color: transparent;
    color: #9c27b0;
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-help.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(156, 39, 176, 0.16);
    border-color: transparent;
    color: #9c27b0;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button {
    background-color: transparent;
    color: $error-500;
    border: 1px solid;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(211, 47, 47, 0.04);
    color: $error-500;
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-outlined
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(211, 47, 47, 0.16);
    color: $error-500;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button {
    background-color: transparent;
    color: $error-500;
    border-color: transparent;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover,
.p-splitbutton.p-button-danger.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(211, 47, 47, 0.04);
    border-color: transparent;
    color: $error-500;
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active,
.p-splitbutton.p-button-danger.p-button-text
    > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(211, 47, 47, 0.16);
    border-color: transparent;
    color: $error-500;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin: 0.5rem;
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-carousel .p-carousel-indicators {
    padding: 1rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #e9ecef;
    width: 2rem;
    height: 0.5rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 0;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: $border-surface-color;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: #e3f2fd;
    color: $text-color-secondary;
}

.p-datatable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-datatable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-datatable .p-datatable-header {
    background: #f8f9fa;
    color: $text-color-secondary;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-datatable .p-datatable-footer {
    background: #f8f9fa;
    color: $text-color-secondary;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: $text-color-secondary;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: $text-color-secondary;
    background: #f8f9fa;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: $grey-color;
    margin-left: 0.5rem;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: $text-color-secondary;
    background: #e3f2fd;
    margin-left: 0.5rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-datatable
    .p-sortable-column:not(.p-highlight):hover
    .p-sortable-column-icon {
    color: $grey-color;
}

.p-datatable .p-sortable-column.p-highlight {
    background: #f8f9fa;
    color: $secondary-button-500;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: $secondary-button-500;
}

.p-datatable .p-sortable-column.p-highlight:hover {
    background: #e9ecef;
    color: $secondary-button-500;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: $secondary-button-500;
}

.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
    outline: 0 none;
}

.p-datatable .p-datatable-tbody > tr {
    background: $color-white;
    color: $text-color-secondary;
    transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: 0.5rem;
}

.p-datatable .p-datatable-tbody > tr:focus {
    outline: 0.15rem solid $secondary-button-050;
    outline-offset: 0.15rem;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #e3f2fd;
    color: $text-color-secondary;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 #e3f2fd;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 #e3f2fd;
}

.p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr:not(.p-highlight):hover {
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-datatable .p-column-resizer-helper {
    background: $secondary-button-500;
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
    background: #f8f9fa;
}

.p-datatable.p-datatable-scrollable
    > .p-datatable-wrapper
    > .p-datatable-table
    > .p-datatable-thead,
.p-datatable.p-datatable-scrollable
    > .p-datatable-wrapper
    > .p-datatable-table
    > .p-datatable-tfoot {
    background-color: #f8f9fa;
}

.p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: #fcfcfc;
}

.p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even).p-highlight {
    background: #e3f2fd;
    color: $text-color-secondary;
}

.p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even).p-highlight
    .p-row-toggler {
    color: $text-color-secondary;
}

.p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even).p-highlight
    .p-row-toggler:hover {
    color: $text-color-secondary;
}

.p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
    padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-dataview .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-dataview .p-dataview-header {
    background: #f8f9fa;
    color: $text-color-secondary;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-dataview .p-dataview-content {
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    padding: 0;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: solid #e9ecef;
    border-width: 0 0 1px 0;
}

.p-dataview .p-dataview-footer {
    background: #f8f9fa;
    color: $text-color-secondary;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-dataview .p-dataview-loading-icon {
    font-size: 2rem;
}

.p-dataview .p-dataview-emptymessage {
    padding: 1rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0.5rem;
}

.p-column-filter-menu-button {
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-menu-button:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: #e3f2fd;
    color: $text-color-secondary;
}

.p-column-filter-menu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-column-filter-clear-button {
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-clear-button:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.p-column-filter-clear-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-column-filter-overlay {
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    min-width: 12.5rem;
}

.p-column-filter-overlay .p-column-filter-row-items {
    padding: 0.5rem 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: $text-color-secondary;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
}

.p-column-filter-overlay
    .p-column-filter-row-items
    .p-column-filter-row-item.p-highlight {
    color: $text-color-secondary;
    background: #e3f2fd;
}

.p-column-filter-overlay
    .p-column-filter-row-items
    .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: $text-color-secondary;
    background: #e9ecef;
}

.p-column-filter-overlay
    .p-column-filter-row-items
    .p-column-filter-row-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid $border-surface-color;
    margin: 0.25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: $text-color-secondary;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 1rem;
    border-bottom: 1px solid $border-surface-color;
}

.p-column-filter-overlay-menu
    .p-column-filter-constraint
    .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu
    .p-column-filter-constraint
    .p-column-filter-remove-button {
    margin-top: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0.5rem 1rem;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 1rem;
}

.fc.fc-unthemed .fc-view-container th {
    background: #f8f9fa;
    border: 1px solid $border-surface-color;
    color: $text-color-secondary;
}

.fc.fc-unthemed .fc-view-container td.fc-widget-content {
    background: $color-white;
    border: 1px solid $border-surface-color;
    color: $text-color-secondary;
}

.fc.fc-unthemed .fc-view-container td.fc-head-container {
    border: 1px solid $border-surface-color;
}

.fc.fc-unthemed .fc-view-container .fc-row {
    border-right: 1px solid $border-surface-color;
}

.fc.fc-unthemed .fc-view-container .fc-event {
    background: $secondary-button-700;
    border: 1px solid $secondary-button-700;
    color: $color-white;
}

.fc.fc-unthemed .fc-view-container .fc-divider {
    background: #f8f9fa;
    border: 1px solid $border-surface-color;
}

.fc.fc-unthemed .fc-toolbar .fc-button {
    color: $color-white;
    background: $secondary-button-500;
    border: 1px solid $secondary-button-500;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
    background: $secondary-button-700;
    color: $color-white;
    border-color: $secondary-button-700;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
    background: $secondary-button-500;
    color: $color-white;
    border-color: $secondary-button-500;
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: 'PrimeIcons' !important;
    text-indent: 0;
    font-size: 1rem;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: '\e900';
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: 'PrimeIcons' !important;
    text-indent: 0;
    font-size: 1rem;
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: '\e901';
}

.fc.fc-unthemed .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: $color-white;
    border: 1px solid #ced4da;
    color: $text-color-secondary;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
    background: #e9ecef;
    border-color: #ced4da;
    color: $text-color-secondary;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
    background: $secondary-button-500;
    border-color: $secondary-button-500;
    color: $color-white;
}

.fc.fc-unthemed
    .fc-toolbar
    .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-unthemed
    .fc-toolbar
    .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-unthemed
    .fc-toolbar
    .fc-button.fc-timeGridDay-button.fc-button-active:hover {
    background: $secondary-button-700;
    border-color: $secondary-button-700;
    color: $color-white;
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,
.fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
    z-index: 1;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
    border-color: $border-surface-color;
}

.fc.fc-theme-standard .fc-view-harness th {
    background: #f8f9fa;
    border-color: $border-surface-color;
    color: $text-color-secondary;
}

.fc.fc-theme-standard .fc-view-harness td {
    color: $text-color-secondary;
    border-color: $border-surface-color;
}

.fc.fc-theme-standard .fc-view-harness .fc-view {
    background: $color-white;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover {
    background: none;
    border: 0 none;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
    border: 1px solid $border-surface-color;
    padding: 1rem;
    background: #f8f9fa;
    color: $text-color-secondary;
}

.fc.fc-theme-standard
    .fc-view-harness
    .fc-popover
    .fc-popover-header
    .fc-popover-close {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: 'PrimeIcons' !important;
    font-size: 1rem;
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.fc.fc-theme-standard
    .fc-view-harness
    .fc-popover
    .fc-popover-header
    .fc-popover-close:before {
    content: '\e90b';
}

.fc.fc-theme-standard
    .fc-view-harness
    .fc-popover
    .fc-popover-header
    .fc-popover-close:enabled:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.fc.fc-theme-standard
    .fc-view-harness
    .fc-popover
    .fc-popover-header
    .fc-popover-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
    padding: 1rem;
    border: 1px solid $border-surface-color;
    background: $color-white;
    color: $text-color-secondary;
    border-top: 0 none;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
    color: $color-white;
    background: $secondary-button-700;
    border-color: $secondary-button-700;
}

.fc.fc-theme-standard
    .fc-view-harness
    .fc-event.fc-daygrid-block-event
    .fc-event-main {
    color: $color-white;
}

.fc.fc-theme-standard
    .fc-view-harness
    .fc-event.fc-daygrid-dot-event
    .fc-daygrid-event-dot {
    background: $secondary-button-700;
    border-color: $secondary-button-700;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
    background: #e9ecef;
    color: $text-color-secondary;
}

.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
    background: #f8f9fa;
}

.fc.fc-theme-standard .fc-toolbar .fc-button {
    color: $color-white;
    background: $secondary-button-500;
    border: 1px solid $secondary-button-500;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 3px;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
    background: $secondary-button-700;
    color: $color-white;
    border-color: $secondary-button-700;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
    background: $secondary-button-500;
    color: $color-white;
    border-color: $secondary-button-500;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
    opacity: 0.6;
    color: $color-white;
    background: $secondary-button-500;
    border: 1px solid $secondary-button-500;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: 'PrimeIcons' !important;
    text-indent: 0;
    font-size: 1rem;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: '\e900';
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: 'PrimeIcons' !important;
    text-indent: 0;
    font-size: 1rem;
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: '\e901';
}

.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: $color-white;
    border: 1px solid #ced4da;
    color: $text-color-secondary;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
    background: #e9ecef;
    border-color: #ced4da;
    color: $text-color-secondary;
}

.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-dayGridMonth-button.fc-button-active,
.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-timeGridWeek-button.fc-button-active,
.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-timeGridDay-button.fc-button-active {
    background: $secondary-button-500;
    border-color: $secondary-button-500;
    color: $color-white;
}

.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-timeGridDay-button.fc-button-active:hover {
    background: $secondary-button-700;
    border-color: $secondary-button-700;
    color: $color-white;
}

.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-dayGridMonth-button:not(:disabled):focus,
.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-timeGridWeek-button:not(:disabled):focus,
.fc.fc-theme-standard
    .fc-toolbar
    .fc-button.fc-timeGridDay-button:not(:disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
    z-index: 1;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.fc.fc-theme-standard a {
    color: $text-color-secondary;
}

.fc.fc-theme-standard .fc-highlight {
    color: $text-color-secondary;
    background: #e3f2fd;
}

.p-orderlist .p-orderlist-controls {
    padding: 1rem;
}

.p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: 0.5rem;
}

.p-orderlist .p-orderlist-header {
    background: #f8f9fa;
    color: $text-color-secondary;
    border: 1px solid $border-surface-color;
    padding: 1rem;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-orderlist .p-orderlist-header .p-orderlist-title {
    font-weight: 600;
}

.p-orderlist .p-orderlist-filter-container {
    padding: 1rem;
    background: $color-white;
    border: 1px solid $border-surface-color;
    border-bottom: 0 none;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
    padding-right: 1.5rem;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    right: 0.5rem;
    color: $grey-color;
}

.p-orderlist .p-orderlist-list {
    border: 1px solid $border-surface-color;
    background: $color-white;
    color: $text-color-secondary;
    padding: 0.5rem 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.5rem 1rem;
    margin: 0;
    border: 0 none;
    color: $text-color-secondary;
    background: transparent;
    transition: box-shadow 0.2s;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: $text-color-secondary;
    background: #e3f2fd;
}

.p-orderlist .p-orderlist-list .p-orderlist-empty-message {
    padding: 0.5rem 1rem;
    color: $text-color-secondary;
}

.p-orderlist
    .p-orderlist-list:not(.cdk-drop-list-dragging)
    .p-orderlist-item:not(.p-highlight):hover {
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-orderlist.p-orderlist-striped
    .p-orderlist-list
    .p-orderlist-item:nth-child(even) {
    background: #e9ecef;
}

.p-orderlist.p-orderlist-striped
    .p-orderlist-list
    .p-orderlist-item:nth-child(even):hover {
    background: #e9ecef;
}

.p-orderlist-item.cdk-drag-preview {
    padding: 0.5rem 1rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border: 0 none;
    color: $text-color-secondary;
    background: $color-white;
    margin: 0;
}

.p-organizationchart
    .p-organizationchart-node-content.p-organizationchart-selectable-node:not(
        .p-highlight
    ):hover {
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: #e3f2fd;
    color: $text-color-secondary;
}

.p-organizationchart
    .p-organizationchart-node-content.p-highlight
    .p-node-toggler
    i {
    color: #6cbbf5;
}

.p-organizationchart .p-organizationchart-line-down {
    background: $border-surface-color;
}

.p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid $border-surface-color;
    border-color: $border-surface-color;
}

.p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid $border-surface-color;
    border-color: $border-surface-color;
}

.p-organizationchart .p-organizationchart-node-content {
    border: 1px solid $border-surface-color;
    background: $color-white;
    color: $text-color-secondary;
    padding: 1rem;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-paginator {
    background: $color-white;
    color: $grey-color;
    border: solid #e9ecef;
    border-width: 0;
    padding: 0.5rem 1rem;
    border-radius: 3px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: $grey-color;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 3px;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: #e9ecef;
    border-color: transparent;
    color: $text-color-secondary;
}

.p-paginator .p-paginator-first {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-paginator .p-paginator-last {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-paginator .p-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 2.357rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
}

.p-paginator .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 2.357rem;
}

.p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: $grey-color;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: $grey-color;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 3px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #e3f2fd;
    border-color: #e3f2fd;
    color: $text-color-secondary;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: #e9ecef;
    border-color: transparent;
    color: $text-color-secondary;
}

.p-picklist .p-picklist-buttons {
    padding: 1rem;
}

.p-picklist .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem;
}

.p-picklist .p-picklist-header {
    background: #f8f9fa;
    color: $text-color-secondary;
    border: 1px solid $border-surface-color;
    padding: 1rem;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-picklist .p-picklist-header .p-picklist-title {
    font-weight: 600;
}

.p-picklist .p-picklist-filter-container {
    padding: 1rem;
    background: $color-white;
    border: 1px solid $border-surface-color;
    border-bottom: 0 none;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
    padding-right: 1.5rem;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    right: 0.5rem;
    color: $grey-color;
}

.p-picklist .p-picklist-list {
    border: 1px solid $border-surface-color;
    background: $color-white;
    color: $text-color-secondary;
    padding: 0.5rem 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.5rem 1rem;
    margin: 0;
    border: 0 none;
    color: $text-color-secondary;
    background: transparent;
    transition: box-shadow 0.2s;
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: $text-color-secondary;
    background: #e3f2fd;
}

.p-picklist .p-picklist-list .p-picklist-empty-message {
    padding: 0.5rem 1rem;
    color: $text-color-secondary;
}

.p-picklist
    .p-picklist-list:not(.cdk-drop-list-dragging)
    .p-picklist-item:not(.p-highlight):hover {
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-picklist.p-picklist-striped
    .p-picklist-list
    .p-picklist-item:nth-child(even) {
    background: #e9ecef;
}

.p-picklist.p-picklist-striped
    .p-picklist-list
    .p-picklist-item:nth-child(even):hover {
    background: #e9ecef;
}

.p-picklist-item.cdk-drag-preview {
    padding: 0.5rem 1rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border: 0 none;
    color: $text-color-secondary;
    background: $color-white;
    margin: 0;
}

.p-timeline .p-timeline-event-marker {
    border: 2px solid $secondary-button-500;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: $color-white;
}

.p-timeline .p-timeline-event-connector {
    background-color: $border-surface-color;
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem;
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px;
}

.p-tree {
    border: 1px solid $border-surface-color;
    background: $color-white;
    color: $text-color-secondary;
    padding: 1rem;
    border-radius: 3px;
}

.p-tree .p-tree-container .p-treenode {
    padding: 0.143rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 3px;
    transition: box-shadow 0.2s;
    padding: 0;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content
    .p-tree-toggler:enabled:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content
    .p-tree-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    margin-right: 0.5rem;
    color: $grey-color;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content
    .p-checkbox
    .p-indeterminate
    .p-checkbox-icon {
    color: $text-color-secondary;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: #e3f2fd;
    color: $text-color-secondary;
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-tree-toggler,
.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-treenode-icon {
    color: $text-color-secondary;
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-tree-toggler:hover,
.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-treenode-icon:hover {
    color: $text-color-secondary;
}

.p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-tree .p-tree-filter-container {
    margin-bottom: 0.5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
    right: 0.5rem;
    color: $grey-color;
}

.p-tree .p-treenode-children {
    padding: 0 0 0 1rem;
}

.p-tree .p-tree-loading-icon {
    font-size: 2rem;
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: #89c8f7;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
    border-radius: 3px;
    border: 1px solid $border-surface-color;
    background-color: $color-white;
    color: $text-color-secondary;
    padding: 0;
    transition: box-shadow 0.2s;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
    background-color: #e3f2fd;
    color: $text-color-secondary;
}

.p-tree.p-tree-horizontal
    .p-treenode
    .p-treenode-content.p-highlight
    .p-treenode-icon {
    color: $text-color-secondary;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
    color: $grey-color;
    margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal
    .p-treenode
    .p-treenode-content
    .p-treenode-label:not(.p-highlight):hover {
    background-color: inherit;
    color: inherit;
}

.p-tree.p-tree-horizontal
    .p-treenode
    .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-treetable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-treetable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
}

.p-treetable .p-treetable-header {
    background: #f8f9fa;
    color: $text-color-secondary;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-treetable .p-treetable-footer {
    background: #f8f9fa;
    color: $text-color-secondary;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-treetable .p-treetable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: $text-color-secondary;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.p-treetable .p-treetable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: $text-color-secondary;
    background: #f8f9fa;
}

.p-treetable .p-sortable-column {
    outline-color: $secondary-button-050;
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
    color: $grey-color;
    margin-left: 0.5rem;
}

.p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: $text-color-secondary;
    background: #e3f2fd;
    margin-left: 0.5rem;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-treetable
    .p-sortable-column:not(.p-highlight):hover
    .p-sortable-column-icon {
    color: $grey-color;
}

.p-treetable .p-sortable-column.p-highlight {
    background: #f8f9fa;
    color: $secondary-button-500;
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: $secondary-button-500;
}

.p-treetable .p-treetable-tbody > tr {
    background: $color-white;
    color: $text-color-secondary;
    transition: box-shadow 0.2s;
}

.p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
    margin-right: 0.5rem;
}

.p-treetable
    .p-treetable-tbody
    > tr
    > td
    p-treetablecheckbox
    .p-checkbox
    .p-indeterminate
    .p-checkbox-icon {
    color: $text-color-secondary;
}

.p-treetable .p-treetable-tbody > tr:focus {
    outline: 0.15rem solid $secondary-button-050;
    outline-offset: -0.15rem;
}

.p-treetable .p-treetable-tbody > tr.p-highlight {
    background: #e3f2fd;
    color: $text-color-secondary;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
    color: $text-color-secondary;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
    color: $text-color-secondary;
}

.p-treetable.p-treetable-hoverable-rows
    .p-treetable-tbody
    > tr:not(.p-highlight):hover {
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-treetable.p-treetable-hoverable-rows
    .p-treetable-tbody
    > tr:not(.p-highlight):hover
    .p-treetable-toggler {
    color: $text-color-secondary;
}

.p-treetable .p-column-resizer-helper {
    background: $secondary-button-500;
}

.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
    background: #f8f9fa;
}

.p-treetable .p-treetable-loading-icon {
    font-size: 2rem;
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
    border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
    border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
    border-width: 1px;
}

.p-treetable.p-treetable-sm .p-treetable-header {
    padding: 0.875rem 0.875rem;
}

.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-footer {
    padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-lg .p-treetable-header {
    padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
    padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
    padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
    padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-footer {
    padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
    background: #f8f9fa;
    color: $text-color-secondary;
    border: 1px solid #e9ecef;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
}

.p-virtualscroller .p-virtualscroller-content {
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    padding: 0;
}

.p-virtualscroller .p-virtualscroller-footer {
    background: #f8f9fa;
    color: $text-color-secondary;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem;
    border: 1px solid $border-surface-color;
    color: $text-color-secondary;
    background: #f8f9fa;
    font-weight: 600;
    border-radius: 3px;
    transition: box-shadow 0.2s;
}

.p-accordion
    .p-accordion-header
    .p-accordion-header-link
    .p-accordion-toggle-icon {
    margin-right: 0.5rem;
}

.p-accordion
    .p-accordion-header:not(.p-disabled)
    .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-accordion
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link {
    background: #e9ecef;
    border-color: $border-surface-color;
    color: $text-color-secondary;
}

.p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    background: #f8f9fa;
    border-color: $border-surface-color;
    color: $text-color-secondary;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    border-color: $border-surface-color;
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-accordion .p-accordion-content {
    padding: 1rem;
    border: 1px solid $border-surface-color;
    background: $color-white;
    color: $text-color-secondary;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-accordion p-accordiontab .p-accordion-tab {
    margin-bottom: 0;
}

.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
    border-radius: 0;
}

.p-accordion p-accordiontab .p-accordion-content {
    border-radius: 0;
}

.p-accordion
    p-accordiontab:not(:first-child)
    .p-accordion-header
    .p-accordion-header-link {
    border-top: 0 none;
}

.p-accordion
    p-accordiontab:not(:first-child)
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link,
.p-accordion
    p-accordiontab:not(:first-child)
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    border-top: 0 none;
}

.p-accordion
    p-accordiontab:first-child
    .p-accordion-header
    .p-accordion-header-link {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-accordion
    p-accordiontab:last-child
    .p-accordion-header:not(.p-highlight)
    .p-accordion-header-link {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-accordion p-accordiontab:last-child .p-accordion-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-card {
    background: $color-white;
    color: $text-color-secondary;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}

.p-card .p-card-body {
    padding: 1rem;
}

.p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.p-card .p-card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: $grey-color;
}

.p-card .p-card-content {
    padding: 1rem 0;
}

.p-card .p-card-footer {
    padding: 1rem 0 0 0;
}

.p-divider .p-divider-content {
    background-color: $color-white;
}

.p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem;
}

.p-divider.p-divider-horizontal:before {
    border-top: 1px $border-surface-color;
}

.p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 0.5rem;
}

.p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0;
}

.p-divider.p-divider-vertical:before {
    border-left: 1px $border-surface-color;
}

.p-divider.p-divider-vertical .p-divider-content {
    padding: 0.5rem 0;
}

.p-fieldset {
    border: 1px solid $border-surface-color;
    background: $color-white;
    color: $text-color-secondary;
    border-radius: 3px;
}

.p-fieldset .p-fieldset-legend {
    padding: 1rem;
    border: 1px solid $border-surface-color;
    color: $text-color-secondary;
    background: #f8f9fa;
    font-weight: 600;
    border-radius: 3px;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 1rem;
    color: $text-color-secondary;
    border-radius: 3px;
    transition: box-shadow 0.2s;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: 0.5rem;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: #e9ecef;
    border-color: $border-surface-color;
    color: $text-color-secondary;
}

.p-fieldset .p-fieldset-content {
    padding: 1rem;
}

.p-panel .p-panel-header {
    border: 1px solid $border-surface-color;
    padding: 1rem;
    background: #f8f9fa;
    color: $text-color-secondary;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-panel .p-panel-header .p-panel-title {
    font-weight: 600;
}

.p-panel .p-panel-header .p-panel-header-icon {
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-panel.p-panel-toggleable .p-panel-header {
    padding: 0.5rem 1rem;
}

.p-panel .p-panel-content {
    padding: 1rem;
    border: 1px solid $border-surface-color;
    background: $color-white;
    color: $text-color-secondary;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 0 none;
}

.p-panel .p-panel-footer {
    padding: 0.5rem 1rem;
    border: 1px solid $border-surface-color;
    background: $color-white;
    color: $text-color-secondary;
    border-top: 0 none;
}

.p-panel .p-panel-icons-end {
    order: 2;
    margin-left: auto;
}

.p-panel .p-panel-icons-start {
    order: 0;
    margin-right: 0.5rem;
}

.p-panel .p-panel-icons-center {
    order: 2;
    width: 100%;
    text-align: center;
}

.p-scrollpanel .p-scrollpanel-bar {
    background: #f8f9fa;
    border: 0 none;
}

.p-splitter {
    border: 1px solid $border-surface-color;
    background: $color-white;
    border-radius: 3px;
    color: $text-color-secondary;
}

.p-splitter .p-splitter-gutter {
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    background: #f8f9fa;
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: $border-surface-color;
}

.p-splitter .p-splitter-gutter-resizing {
    background: $border-surface-color;
}

.p-tabview .p-tabview-nav {
    background: $color-white;
    border: 1px solid $border-surface-color;
    border-width: 0 0 2px 0;
}

.p-tabview .p-tabview-nav li {
    margin-right: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid $border-surface-color;
    border-width: 0 0 2px 0;
    border-color: transparent transparent $border-surface-color transparent;
    background: $color-white;
    color: $grey-color;
    padding: 1rem;
    font-weight: 600;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem $secondary-button-050;
}

.p-tabview
    .p-tabview-nav
    li:not(.p-highlight):not(.p-disabled):hover
    .p-tabview-nav-link {
    background: $color-white;
    border-color: $grey-color;
    color: $grey-color;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: $color-white;
    border-color: $secondary-button-500;
    color: $secondary-button-500;
}

.p-tabview .p-tabview-left-icon {
    margin-right: 0.5rem;
}

.p-tabview .p-tabview-right-icon {
    margin-left: 0.5rem;
}

.p-tabview .p-tabview-close {
    margin-left: 0.5rem;
}

.p-tabview .p-tabview-nav-btn.p-link {
    background: $color-white;
    color: $secondary-button-500;
    width: 2.357rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
}

.p-tabview .p-tabview-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem $secondary-button-050;
}

.p-tabview .p-tabview-panels {
    background: $color-white;
    padding: 1rem;
    border: 0 none;
    color: $text-color-secondary;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-toolbar {
    background: #f8f9fa;
    border: 1px solid $border-surface-color;
    padding: 1rem;
    border-radius: 3px;
}

.p-toolbar .p-toolbar-separator {
    margin: 0 0.5rem;
}

.p-confirm-popup {
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-confirm-popup .p-confirm-popup-content {
    padding: 1rem;
}

.p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: 0.5rem 1rem;
}

.p-confirm-popup .p-confirm-popup-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}

.p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0;
}

.p-confirm-popup:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $color-white;
}

.p-confirm-popup:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $color-white;
}

.p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: $color-white;
}

.p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: $color-white;
}

.p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem;
}

.p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem;
}

.p-dialog {
    border-radius: 3px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border: 0 none;
}

.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: $color-white;
    color: $text-color-secondary;
    padding: 1.5rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 1.25rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
}

.p-dialog .p-dialog-content {
    background: $color-white;
    color: $text-color-secondary;
    padding: 0 1.5rem 2rem 1.5rem;
}

.p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: $color-white;
    color: $text-color-secondary;
    padding: 0 1.5rem 1.5rem 1.5rem;
    text-align: right;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
}

.p-overlaypanel {
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-overlaypanel .p-overlaypanel-content {
    padding: 1rem;
}

.p-overlaypanel .p-overlaypanel-close {
    background: $secondary-button-500;
    color: $color-white;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem;
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: $secondary-button-700;
    color: $color-white;
}

.p-overlaypanel:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $color-white;
}

.p-overlaypanel:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $color-white;
}

.p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: $color-white;
}

.p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: $color-white;
}

.p-sidebar {
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-sidebar .p-sidebar-header {
    padding: 1rem;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 2rem;
    height: 2rem;
    color: $grey-color;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: $text-color-secondary;
    border-color: transparent;
    background: #e9ecef;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding-top: 0;
}

.p-sidebar .p-sidebar-content {
    padding: 1rem;
}

.p-tooltip .p-tooltip-text {
    background: $text-color-secondary;
    color: $color-white;
    padding: 0.5rem 0.5rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: $text-color-secondary;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: $text-color-secondary;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: $text-color-secondary;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: $text-color-secondary;
}

.p-fileupload .p-fileupload-buttonbar {
    background: #f8f9fa;
    padding: 1rem;
    border: 1px solid $border-surface-color;
    color: $text-color-secondary;
    border-bottom: 0 none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 0.5rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-fileupload .p-fileupload-content {
    background: $color-white;
    padding: 2rem 1rem;
    border: 1px solid $border-surface-color;
    color: $text-color-secondary;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-fileupload .p-progressbar {
    height: 0.25rem;
}

.p-fileupload .p-fileupload-row > div {
    padding: 1rem 1rem;
}

.p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
    background: $secondary-button-700;
    color: $color-white;
    border-color: $secondary-button-700;
}

.p-fileupload-choose:not(.p-disabled):active {
    background: #0b7ad1;
    color: $color-white;
    border-color: #0b7ad1;
}

.p-breadcrumb {
    background: $color-white;
    border: 1px solid $border-surface-color;
    border-radius: 3px;
    padding: 1rem;
}

.p-breadcrumb ul li .p-menuitem-link {
    transition: box-shadow 0.2s;
    border-radius: 3px;
}

.p-breadcrumb ul li .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    color: $text-color-secondary;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
    color: $grey-color;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
    margin: 0 0.5rem 0 0.5rem;
    color: $text-color-secondary;
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
    color: $text-color-secondary;
}

.p-breadcrumb ul li:last-child .p-menuitem-icon {
    color: $grey-color;
}

.p-contextmenu {
    padding: 0.25rem 0;
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    width: 12.5rem;
}

.p-contextmenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
    color: $text-color-secondary;
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
    color: $grey-color;
    margin-right: 0.5rem;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
    color: $grey-color;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: $text-color-secondary;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: $grey-color;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: $grey-color;
}

.p-contextmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-contextmenu .p-submenu-list {
    padding: 0.25rem 0;
    background: $color-white;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}

.p-contextmenu .p-menuitem {
    margin: 0;
}

.p-contextmenu .p-menuitem:last-child {
    margin: 0;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #e9ecef;
}

.p-contextmenu
    .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-text {
    color: $text-color-secondary;
}

.p-contextmenu
    .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-icon,
.p-contextmenu
    .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-submenu-icon {
    color: $grey-color;
}

.p-contextmenu .p-menu-separator {
    border-top: 1px solid $border-surface-color;
    margin: 0.25rem 0;
}

.p-contextmenu .p-submenu-icon {
    font-size: 0.875rem;
}

.p-dock .p-dock-list {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
}

.p-dock .p-dock-item {
    padding: 0.5rem;
}

.p-dock .p-dock-action {
    width: 4rem;
    height: 4rem;
}

.p-dock.p-dock-top .p-dock-item-second-prev,
.p-dock.p-dock-top .p-dock-item-second-next,
.p-dock.p-dock-bottom .p-dock-item-second-prev,
.p-dock.p-dock-bottom .p-dock-item-second-next {
    margin: 0 0.9rem;
}

.p-dock.p-dock-top .p-dock-item-prev,
.p-dock.p-dock-top .p-dock-item-next,
.p-dock.p-dock-bottom .p-dock-item-prev,
.p-dock.p-dock-bottom .p-dock-item-next {
    margin: 0 1.3rem;
}

.p-dock.p-dock-top .p-dock-item-current,
.p-dock.p-dock-bottom .p-dock-item-current {
    margin: 0 1.5rem;
}

.p-dock.p-dock-left .p-dock-item-second-prev,
.p-dock.p-dock-left .p-dock-item-second-next,
.p-dock.p-dock-right .p-dock-item-second-prev,
.p-dock.p-dock-right .p-dock-item-second-next {
    margin: 0.9rem 0;
}

.p-dock.p-dock-left .p-dock-item-prev,
.p-dock.p-dock-left .p-dock-item-next,
.p-dock.p-dock-right .p-dock-item-prev,
.p-dock.p-dock-right .p-dock-item-next {
    margin: 1.3rem 0;
}

.p-dock.p-dock-left .p-dock-item-current,
.p-dock.p-dock-right .p-dock-item-current {
    margin: 1.5rem 0;
}

@media screen and (max-width: 960px) {
    .p-dock.p-dock-top .p-dock-list-container,
    .p-dock.p-dock-bottom .p-dock-list-container {
        overflow-x: auto;
        width: 100%;
    }

    .p-dock.p-dock-top .p-dock-list-container .p-dock-list,
    .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
        margin: 0 auto;
    }

    .p-dock.p-dock-left .p-dock-list-container,
    .p-dock.p-dock-right .p-dock-list-container {
        overflow-y: auto;
        height: 100%;
    }

    .p-dock.p-dock-left .p-dock-list-container .p-dock-list,
    .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
        margin: auto 0;
    }

    .p-dock .p-dock-list .p-dock-item {
        transform: none;
        margin: 0;
    }
}

.p-megamenu {
    padding: 0.5rem;
    background: #f8f9fa;
    color: $text-color-secondary;
    border: 1px solid $border-surface-color;
    border-radius: 3px;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    border-radius: 3px;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-text {
    color: $text-color-secondary;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-icon {
    color: $grey-color;
    margin-right: 0.5rem;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-submenu-icon {
    color: $grey-color;
    margin-left: 0.5rem;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: $text-color-secondary;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-icon {
    color: $grey-color;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-submenu-icon {
    color: $grey-color;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link,
.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-text,
.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: $text-color-secondary;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-icon,
.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-icon {
    color: $grey-color;
}

.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-submenu-icon,
.p-megamenu
    .p-megamenu-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover
    .p-submenu-icon {
    color: $grey-color;
}

.p-megamenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
    color: $text-color-secondary;
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
    color: $grey-color;
    margin-right: 0.5rem;
}

.p-megamenu .p-menuitem-link .p-submenu-icon {
    color: $grey-color;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: $text-color-secondary;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: $grey-color;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: $grey-color;
}

.p-megamenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-megamenu .p-megamenu-panel {
    background: $color-white;
    color: $text-color-secondary;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-megamenu .p-megamenu-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    background: $color-white;
    font-weight: 600;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-megamenu .p-megamenu-submenu {
    padding: 0.25rem 0;
    width: 12.5rem;
}

.p-megamenu .p-megamenu-submenu .p-menu-separator {
    border-top: 1px solid $border-surface-color;
    margin: 0.25rem 0;
}

.p-megamenu .p-megamenu-submenu .p-menuitem {
    margin: 0;
}

.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
    margin: 0;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #e9ecef;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: $text-color-secondary;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: $grey-color;
}

.p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: 0.25rem 0;
}

.p-megamenu.p-megamenu-vertical .p-menuitem {
    margin: 0;
}

.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
    margin: 0;
}

.p-menu {
    padding: 0.25rem 0;
    background: $color-white;
    color: $text-color-secondary;
    border: 1px solid $border-surface-color;
    border-radius: 3px;
    width: 12.5rem;
}

.p-menu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-menu .p-menuitem-link .p-menuitem-text {
    color: $text-color-secondary;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
    color: $grey-color;
    margin-right: 0.5rem;
}

.p-menu .p-menuitem-link .p-submenu-icon {
    color: $grey-color;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: $text-color-secondary;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: $grey-color;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: $grey-color;
}

.p-menu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-menu.p-menu-overlay {
    background: $color-white;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-menu .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    background: $color-white;
    font-weight: 600;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.p-menu .p-menu-separator {
    border-top: 1px solid $border-surface-color;
    margin: 0.25rem 0;
}

.p-menu .p-menuitem {
    margin: 0;
}

.p-menu .p-menuitem:last-child {
    margin: 0;
}

.p-menubar {
    padding: 0.5rem;
    background: #f8f9fa;
    color: $text-color-secondary;
    border: 1px solid $border-surface-color;
    border-radius: 3px;
}

.p-menubar .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
    color: $text-color-secondary;
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
    color: $grey-color;
    margin-right: 0.5rem;
}

.p-menubar .p-menuitem-link .p-submenu-icon {
    color: $grey-color;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: $text-color-secondary;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: $grey-color;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: $grey-color;
}

.p-menubar .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    border-radius: 3px;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-text {
    color: $text-color-secondary;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-icon {
    color: $grey-color;
    margin-right: 0.5rem;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-submenu-icon {
    color: $grey-color;
    margin-left: 0.5rem;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: $text-color-secondary;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-icon {
    color: $grey-color;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover
    .p-submenu-icon {
    color: $grey-color;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link,
.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-text,
.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: $text-color-secondary;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-icon,
.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-icon {
    color: $grey-color;
}

.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-submenu-icon,
.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover
    .p-submenu-icon {
    color: $grey-color;
}

.p-menubar .p-submenu-list {
    padding: 0.25rem 0;
    background: $color-white;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 12.5rem;
}

.p-menubar .p-submenu-list .p-menu-separator {
    border-top: 1px solid $border-surface-color;
    margin: 0.25rem 0;
}

.p-menubar .p-submenu-list .p-submenu-icon {
    font-size: 0.875rem;
}

.p-menubar .p-submenu-list .p-menuitem {
    margin: 0;
}

.p-menubar .p-submenu-list .p-menuitem:last-child {
    margin: 0;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #e9ecef;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: $text-color-secondary;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: $grey-color;
}

@media screen and (max-width: 960px) {
    .p-menubar {
        position: relative;
    }

    .p-menubar .p-menubar-button {
        display: flex;
        width: 2rem;
        height: 2rem;
        color: $grey-color;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }

    .p-menubar .p-menubar-button:hover {
        color: $grey-color;
        background: #e9ecef;
    }

    .p-menubar .p-menubar-button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem $secondary-button-050;
    }

    .p-menubar .p-menubar-root-list {
        position: absolute;
        display: none;
        padding: 0.25rem 0;
        background: $color-white;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
            0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
        width: 100%;
    }

    .p-menubar .p-menubar-root-list .p-menu-separator {
        border-top: 1px solid $border-surface-color;
        margin: 0.25rem 0;
    }

    .p-menubar .p-menubar-root-list .p-submenu-icon {
        font-size: 0.875rem;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem {
        width: 100%;
        position: static;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
        padding: 0.75rem 1rem;
        color: $text-color-secondary;
        border-radius: 0;
        transition: box-shadow 0.2s;
        user-select: none;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link
        .p-menuitem-text {
        color: $text-color-secondary;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link
        .p-menuitem-icon {
        color: $grey-color;
        margin-right: 0.5rem;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link
        .p-submenu-icon {
        color: $grey-color;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link:not(.p-disabled):hover {
        background: #e9ecef;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link:not(.p-disabled):hover
        .p-menuitem-text {
        color: $text-color-secondary;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link:not(.p-disabled):hover
        .p-menuitem-icon {
        color: $grey-color;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link:not(.p-disabled):hover
        .p-submenu-icon {
        color: $grey-color;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem
        > .p-menuitem-link
        > .p-submenu-icon {
        margin-left: auto;
        transition: transform 0.2s;
    }

    .p-menubar
        .p-menubar-root-list
        > .p-menuitem.p-menuitem-active
        > .p-menuitem-link
        > .p-submenu-icon {
        transform: rotate(-180deg);
    }

    .p-menubar .p-menubar-root-list .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none;
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
        transition: transform 0.2s;
        transform: rotate(90deg);
    }

    .p-menubar
        .p-menubar-root-list
        .p-submenu-list
        .p-menuitem-active
        > .p-menuitem-link
        > .p-submenu-icon {
        transform: rotate(-90deg);
    }

    .p-menubar .p-menubar-root-list .p-menuitem {
        width: 100%;
        position: static;
    }

    .p-menubar .p-menubar-root-list ul li a {
        padding-left: 2.25rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li a {
        padding-left: 3.75rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li a {
        padding-left: 5.25rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
        padding-left: 6.75rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
        padding-left: 8.25rem;
    }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
        display: flex;
        flex-direction: column;
        top: 100%;
        left: 0;
        z-index: 1;
    }
}

.p-panelmenu .p-panelmenu-header > a {
    padding: 1rem;
    border: 1px solid $border-surface-color;
    color: $text-color-secondary;
    background: #f8f9fa;
    font-weight: 600;
    border-radius: 3px;
    transition: box-shadow 0.2s;
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
    margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
    margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
    background: #e9ecef;
    border-color: $border-surface-color;
    color: $text-color-secondary;
}

.p-panelmenu .p-panelmenu-header.p-highlight {
    margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
    background: #f8f9fa;
    border-color: $border-surface-color;
    color: $text-color-secondary;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
    border-color: $border-surface-color;
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-panelmenu .p-panelmenu-content {
    padding: 0.25rem 0;
    border: 1px solid $border-surface-color;
    background: $color-white;
    color: $text-color-secondary;
    margin-bottom: 0;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link
    .p-menuitem-text {
    color: $text-color-secondary;
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link
    .p-menuitem-icon {
    color: $grey-color;
    margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
    color: $grey-color;
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-text {
    color: $text-color-secondary;
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link:not(.p-disabled):hover
    .p-menuitem-icon {
    color: $grey-color;
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link:not(.p-disabled):hover
    .p-submenu-icon {
    color: $grey-color;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-link
    .p-panelmenu-icon {
    margin-right: 0.5rem;
}

.p-panelmenu
    .p-panelmenu-content
    .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0 0 0 1rem;
}

.p-panelmenu .p-panelmenu-panel {
    margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
    border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
    border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
    border-top: 0 none;
}

.p-panelmenu
    .p-panelmenu-panel:not(:first-child)
    .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover
    > a,
.p-panelmenu
    .p-panelmenu-panel:not(:first-child)
    .p-panelmenu-header:not(.p-disabled).p-highlight:hover
    > a {
    border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-panelmenu
    .p-panelmenu-panel:last-child
    .p-panelmenu-header:not(.p-highlight)
    > a {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-slidemenu {
    padding: 0.25rem 0;
    background: $color-white;
    color: $text-color-secondary;
    border: 1px solid $border-surface-color;
    border-radius: 3px;
    width: 12.5rem;
}

.p-slidemenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-slidemenu .p-menuitem-link .p-menuitem-text {
    color: $text-color-secondary;
}

.p-slidemenu .p-menuitem-link .p-menuitem-icon {
    color: $grey-color;
    margin-right: 0.5rem;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
    color: $grey-color;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: $text-color-secondary;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: $grey-color;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: $grey-color;
}

.p-slidemenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-slidemenu.p-slidemenu-overlay {
    background: $color-white;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-slidemenu .p-slidemenu-list {
    padding: 0.25rem 0;
    background: $color-white;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
    background: #e9ecef;
}

.p-slidemenu
    .p-slidemenu.p-slidemenu-active
    > .p-slidemenu-link
    .p-slidemenu-text {
    color: $text-color-secondary;
}

.p-slidemenu
    .p-slidemenu.p-slidemenu-active
    > .p-slidemenu-link
    .p-slidemenu-icon,
.p-slidemenu
    .p-slidemenu.p-slidemenu-active
    > .p-slidemenu-link
    .p-slidemenu-icon {
    color: $grey-color;
}

.p-slidemenu .p-slidemenu-separator {
    border-top: 1px solid $border-surface-color;
    margin: 0.25rem 0;
}

.p-slidemenu .p-slidemenu-icon {
    font-size: 0.875rem;
}

.p-slidemenu .p-slidemenu-backward {
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
}

.p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 3px;
    background: $color-white;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: $text-color-secondary;
    border: 1px solid #e9ecef;
    background: $color-white;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 50%;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: $grey-color;
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #e3f2fd;
    color: $text-color-secondary;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 600;
    color: $text-color-secondary;
}

.p-steps .p-steps-item:before {
    content: ' ';
    border-top: 1px solid $border-surface-color;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
    background: $color-white;
    border: 1px solid $border-surface-color;
    border-width: 0 0 2px 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: solid $border-surface-color;
    border-width: 0 0 2px 0;
    border-color: transparent transparent $border-surface-color transparent;
    background: $color-white;
    color: $grey-color;
    padding: 1rem;
    font-weight: 600;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0.5rem;
}

.p-tabmenu
    .p-tabmenu-nav
    .p-tabmenuitem
    .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem $secondary-button-050;
}

.p-tabmenu
    .p-tabmenu-nav
    .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover
    .p-menuitem-link {
    background: $color-white;
    border-color: $grey-color;
    color: $grey-color;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: $color-white;
    border-color: $secondary-button-500;
    color: $secondary-button-500;
}

.p-tabmenu .p-tabmenu-left-icon {
    margin-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-right-icon {
    margin-left: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav-btn.p-link {
    background: $color-white;
    color: $secondary-button-500;
    width: 2.357rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
}

.p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem $secondary-button-050;
}

.p-tieredmenu {
    padding: 0.25rem 0;
    background: $color-white;
    color: $text-color-secondary;
    border: 1px solid $border-surface-color;
    border-radius: 3px;
    width: 12.5rem;
}

.p-tieredmenu .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $text-color-secondary;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: $text-color-secondary;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    color: $grey-color;
    margin-right: 0.5rem;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
    color: $grey-color;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: $text-color-secondary;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: $grey-color;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: $grey-color;
}

.p-tieredmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $secondary-button-050;
}

.p-tieredmenu.p-tieredmenu-overlay {
    background: $color-white;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-tieredmenu .p-submenu-list {
    padding: 0.25rem 0;
    background: $color-white;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-tieredmenu .p-menuitem {
    margin: 0;
}

.p-tieredmenu .p-menuitem:last-child {
    margin: 0;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #e9ecef;
}

.p-tieredmenu
    .p-menuitem.p-menuitem-active
    > .p-menuitem-link
    .p-menuitem-text {
    color: $text-color-secondary;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: $grey-color;
}

.p-tieredmenu .p-menu-separator {
    border-top: 1px solid $border-surface-color;
    margin: 0.25rem 0;
}

.p-tieredmenu .p-submenu-icon {
    font-size: 0.875rem;
}

.p-inline-message {
    padding: 0.5rem 0.5rem;
    margin: 0;
    border-radius: 3px;
}

.p-inline-message.p-inline-message-info {
    background: $secondary-button-050;
    border: solid;
    border-width: 0px;
    color: $secondary-button-700;
}

.p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: $secondary-button-700;
}

.p-inline-message.p-inline-message-success {
    background: $primary-button-050;
    border: solid $primary-button-500;
    border-width: 0px;
    color: $primary-button-700;
}

.p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: $primary-button-700;
}

.p-inline-message.p-inline-message-warn {
    background: $information-050;
    border: solid $information-500;
    border-width: 0px;
    color: $information-700;
}

.p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: $information-700;
}

.p-inline-message.p-inline-message-error {
    background: $error-050;
    border: solid $error-500;
    border-width: 0px;
    color: $error-700;
}

.p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: $error-700;
}

.p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
}

.p-inline-message .p-inline-message-text {
    font-size: 1rem;
}

.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
}

.p-message {
    margin: 1rem 0;
    border-radius: 3px;
}

.p-message .p-message-wrapper {
    padding: 1rem 1.5rem;
}

.p-message .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-message .p-message-close:hover {
    background: rgba(255, 255, 255, 0.3);
}

.p-message .p-message-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-message.p-message-info {
    background: $secondary-button-050;
    border: solid $secondary-button-500;
    border-width: 0 0 0 6px;
    color: $secondary-button-700;
}

.p-message.p-message-info .p-message-icon {
    color: $secondary-button-700;
}

.p-message.p-message-info .p-message-close {
    color: $secondary-button-700;
}

.p-message.p-message-success {
    background: $primary-button-050;
    border: solid $primary-button-500;
    border-width: 0 0 0 6px;
    color: $primary-button-700;
}

.p-message.p-message-success .p-message-icon {
    color: $primary-button-700;
}

.p-message.p-message-success .p-message-close {
    color: $primary-button-700;
}

.p-message.p-message-warn {
    background: $information-050;
    border: solid $information-500;
    border-width: 0 0 0 6px;
    color: $information-700;
}

.p-message.p-message-warn .p-message-icon {
    color: $information-700;
}

.p-message.p-message-warn .p-message-close {
    color: $information-700;
}

.p-message.p-message-error {
    background: $error-050;
    border: solid $error-500;
    border-width: 0 0 0 6px;
    color: $error-700;
}

.p-message.p-message-error .p-message-icon {
    color: $error-700;
}

.p-message.p-message-error .p-message-close {
    color: $error-700;
}

.p-message .p-message-text {
    font-size: 1rem;
    font-weight: 500;
}

.p-message .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
}

.p-message .p-message-summary {
    font-weight: 700;
}

.p-message .p-message-detail {
    margin-left: 0.5rem;
}

.p-toast {
    opacity: 0.9;
}

.p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
}

.p-toast .p-toast-message .p-toast-message-content {
    padding: 1rem;
    border-width: 0 0 0 6px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 1rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 2rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 700;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: 0.5rem 0 0 0;
}

.p-toast .p-toast-message .p-toast-icon-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, 0.3);
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-toast .p-toast-message.p-toast-message-info {
    background: $secondary-button-050;
    border: solid $secondary-button-500;
    border-width: 0 0 0 6px;
    color: $secondary-button-700;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: $secondary-button-700;
}

.p-toast .p-toast-message.p-toast-message-success {
    background: $primary-button-050;
    border: solid $primary-button-500;
    border-width: 0 0 0 6px;
    color: $primary-button-700;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: $primary-button-700;
}

.p-toast .p-toast-message.p-toast-message-warn {
    background: $information-050;
    border: solid $information-500;
    border-width: 0 0 0 6px;
    color: $information-700;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: $information-700;
}

.p-toast .p-toast-message.p-toast-message-error {
    background: $error-050;
    border: solid $error-500;
    border-width: 0 0 0 6px;
    color: $error-700;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: $error-700;
}

.p-galleria .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: #f8f9fa;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
}

.p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem;
}

.p-galleria .p-galleria-close:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #f8f9fa;
}

.p-galleria .p-galleria-item-nav {
    background: transparent;
    color: #f8f9fa;
    width: 4rem;
    height: 4rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    margin: 0 0.5rem;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem;
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.1);
    color: #f8f9fa;
}

.p-galleria .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: #f8f9fa;
    padding: 1rem;
}

.p-galleria .p-galleria-indicators {
    padding: 1rem;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #e9ecef;
    width: 1rem;
    height: 1rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: $border-surface-color;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #e3f2fd;
    color: $text-color-secondary;
}

.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
.p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: 0.5rem;
}

.p-galleria.p-galleria-indicators-left .p-galleria-indicator,
.p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: 0.5rem;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);
}

.p-galleria.p-galleria-indicator-onitem
    .p-galleria-indicators
    .p-galleria-indicator
    button {
    background: rgba(255, 255, 255, 0.4);
}

.p-galleria.p-galleria-indicator-onitem
    .p-galleria-indicators
    .p-galleria-indicator
    button:hover {
    background: rgba(255, 255, 255, 0.6);
}

.p-galleria.p-galleria-indicator-onitem
    .p-galleria-indicators
    .p-galleria-indicator.p-highlight
    button {
    background: #e3f2fd;
    color: $text-color-secondary;
}

.p-galleria .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0.25rem;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    margin: 0.5rem;
    background-color: transparent;
    color: #f8f9fa;
    width: 2rem;
    height: 2rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50%;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #f8f9fa;
}

.p-galleria
    .p-galleria-thumbnail-container
    .p-galleria-thumbnail-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-galleria-mask {
    $maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-mask {
    $maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-preview-indicator {
    background-color: transparent;
    color: #f8f9fa;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
    background-color: rgba(0, 0, 0, 0.5);
}

.p-image-toolbar {
    padding: 1rem;
}

.p-image-action.p-link {
    color: #f8f9fa;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
}

.p-image-action.p-link:last-child {
    margin-right: 0;
}

.p-image-action.p-link:hover {
    color: #f8f9fa;
    background-color: rgba(255, 255, 255, 0.1);
}

.p-image-action.p-link i {
    font-size: 1.5rem;
}

.p-avatar {
    background-color: $border-surface-color;
    border-radius: 3px;
}

.p-avatar.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
}

.p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem;
}

.p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
}

.p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem;
}

.p-avatar-group .p-avatar {
    border: 2px solid $color-white;
}

.p-badge {
    background: $secondary-button-500;
    color: $color-white;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
}

.p-badge.p-badge-secondary {
    background-color: #607d8b;
    color: $color-white;
}

.p-badge.p-badge-success {
    background-color: $primary-button-500;
    color: $color-white;
}

.p-badge.p-badge-info {
    background-color: $secondary-button-500;
    color: $color-white;
}

.p-badge.p-badge-warning {
    background-color: $information-500;
    color: #212529;
}

.p-badge.p-badge-danger {
    background-color: $error-500;
    color: $color-white;
}

.p-badge.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
}

.p-badge.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
}

.p-chip {
    background-color: $border-surface-color;
    color: $text-color-secondary;
    border-radius: 16px;
    padding: 0 0.5rem;
}

.p-chip .p-chip-text {
    line-height: 1.5;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.p-chip .p-chip-icon {
    margin-right: 0.5rem;
}

.p-chip .pi-chip-remove-icon {
    margin-left: 0.5rem;
}

.p-chip img {
    width: 2rem;
    height: 2rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
}

.p-chip .pi-chip-remove-icon {
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-chip .pi-chip-remove-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-inplace .p-inplace-display {
    padding: 0.5rem 0.5rem;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: #e9ecef;
    color: $text-color-secondary;
}

.p-inplace .p-inplace-display:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $secondary-button-050;
}

.p-progressbar {
    border: 0 none;
    height: 1.5rem;
    background: $border-surface-color;
    border-radius: 3px;
}

.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: $secondary-button-500;
}

.p-progressbar .p-progressbar-label {
    color: $color-white;
    line-height: 1.5rem;
}

.p-scrolltop {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-scrolltop.p-link {
    background: rgba(0, 0, 0, 0.7);
}

.p-scrolltop.p-link:hover {
    background: rgba(0, 0, 0, 0.8);
}

.p-scrolltop .p-scrolltop-icon {
    font-size: 1.5rem;
    color: #f8f9fa;
}

.p-skeleton {
    background-color: #e9ecef;
    border-radius: 3px;
}

.p-skeleton:after {
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0)
    );
}

.p-tag {
    background: $secondary-button-500;
    color: $color-white;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 3px;
}

.p-tag.p-tag-success {
    background-color: $primary-button-500;
    color: $color-white;
}

.p-tag.p-tag-info {
    background-color: $secondary-button-500;
    color: $color-white;
}

.p-tag.p-tag-warning {
    background-color: $information-500;
    color: $color-white;
}

.p-tag.p-tag-danger {
    background-color: $error-500;
    color: $color-white;
}

.p-tag .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
}

.p-terminal {
    background: $color-white;
    color: $text-color-secondary;
    border: 1px solid $border-surface-color;
    padding: 1rem;
}

.p-terminal .p-terminal-input {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
}

/* Customizations to the designer theme should be defined here */
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: $secondary-button-500;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: $secondary-button-500;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 $secondary-button-500;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 $secondary-button-500;
}
