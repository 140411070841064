.dialog-container {
    background: #efefef;
    background-color: #efefef;

    .p-dialog-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #333335;
    }

    .p-dialog-content {
        background: #efefef;
    }
    .p-dialog-content:has(app-modal-maintenance-request-detail) {
        padding: 0 0 2rem 0 !important;
    }
}
