// COLOR THEME PRIME NG
$font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$content-padding: 1rem;
$inline-spacing: 0.5rem;
$border-radius: 3px;
$surface-ground: #f8f9fa;
$surface-section: #ffffff;
$surface-card: #ffffff;
$surface-overlay: #ffffff;
$surface-border: #dee2e6;
$surface-hover: #e9ecef;
$maskbg: rgba(0, 0, 0, 0.4);
$focus-ring: 0 0 0 0.2rem #a6d5fa;

// COLOR APOLLO DESIGN

$primary-button-050: #e1f5ec;
$primary-button-100: #b5e5cf;
$primary-button-200: #84d4af;
$primary-button-300: #53c38f;
$primary-button-400: #2eb677;
$primary-button-500: #09a95f;
$primary-button-600: #08a257;
$primary-button-700: #06984d;
$primary-button-800: #058f43;
$primary-button-900: #027e32;

$secondary-button-050: #e7eaf0;
$secondary-button-100: #c3cbda;
$secondary-button-200: #9ba8c1;
$secondary-button-300: #7285a8;
$secondary-button-400: #546a96;
$secondary-button-500: #365083;
$secondary-button-600: #30497b;
$secondary-button-700: #294070;
$secondary-button-800: #223766;
$secondary-button-900: #162753;

$error-050: #fae4e4;
$error-100: #f3baba;
$error-200: #eb8d8d;
$error-300: #e25f5f;
$error-400: #dc3c3c;
$error-500: #d61a1a;
$error-600: #d11717;
$error-700: #cc1313;
$error-800: #c60f0f;
$error-900: #bc0808;

$information-050: #fef5e7;
$information-100: #fce7c4;
$information-200: #fad79c;
$information-300: #f7c774;
$information-400: #f6bb57;
$information-500: #f4af39;
$information-600: #f3a833;
$information-700: #f19f2c;
$information-800: #ef9624;
$information-900: #ec8617;

$order-color-warehouse: #3d3d3d;
$order-color-pickup: #a845e6;
$order-color-delivery: #4b7ff9;
$order-color-customer: #e645a0;
$order-color-notuse-1: #413dcc;
$order-color-notuse-2: #57bf39;

$mapping-color-1: #d61a1a;
$mapping-color-2: #d99441;
$mapping-color-3: #09a95f;
$mapping-color-4: #061a3e;
$mapping-color-5: #990099;

$primary-color-050: #e3e8ed;
$primary-color-100: #b9c5d3;
$primary-color-200: #8a9eb6;
$primary-color-300: #5b7799;
$primary-color-400: #385a83;
$primary-color-500: #153d6d;
$primary-color-600: #123765;
$primary-color-700: #0f2f5a;
$primary-color-800: #0c2750;
$primary-color-900: #061a3e;

$secondary-color-050: #faf5ec;
$secondary-color-100: #f3e5d1;
$secondary-color-200: #ebd4b2;
$secondary-color-300: #e3c293;
$secondary-color-400: #ddb57b;
$secondary-color-500: #d7a864;
$secondary-color-600: #d3a05c;
$secondary-color-700: #cd9752;
$secondary-color-800: #c78d48;
$secondary-color-900: #be7d36;

$color-white: #ffffff;

$text-color-secondary: #495057;

$grey-color: #6c757d;

$border-surface-color: rgba(255, 255, 255, 0.75);

$pms-color-moderate: #eca249;

$logistic-step-color: #b27f5b;
