@import 'src/scss//variable_color.scss';
@import 'src/scss//modal.scss';

a {
    text-decoration: none;
}

legend {
    font-size: 1rem;
    margin-bottom: 0;
}

.h2-title {
    font-weight: 600;
    font-size: 1.5rem;
    margin-block: 1em;
}

.h3-title {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 600;
}

.h4-title {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 600;
}

h5 {
    font-size: 0.9rem;
}
/**
* to hide all modal headers
.p-dialog .p-dialog-header {
    display: none,
}
/**/

/**
* transport order modal style
.p-dialog .p-dialog-content:last-of-type {
    padding: 1rem !important;
    background: #efefef !important;
}
/**/

/**
* General form styling
*/
.group-container {
    background: white;
    border-radius: 0.31rem;
    padding: 1rem 1rem 1rem 0;
}

.apollo-form {
    padding: 0rem 1rem 1rem 1rem;
    /**
     * General multiform container
     */

    .formgroup-buttons {
        padding-block: 1.5rem;
    }

    .formgroup-title {
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.75rem;
        color: #333335;
        position: relative;
        padding-inline-start: 1rem;
        &:after {
            content: ' ';
            display: block;
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 0.125rem;
            background-color: #e7b76f;
        }
    }

    .formgroup-content {
        padding-inline-start: 1rem;
    }

    .formgroup-item {
        display: grid;
        grid-template-rows: 1.5rem auto;
        gap: 0.5rem;
        label {
            display: block;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem;
        }
    }
}

/** Colors **/
.primary-button-050 {
    color: $primary-button-050;
}

.primary-button-100 {
    color: $primary-button-100;
}

.primary-button-200 {
    color: $primary-button-200;
}

.primary-button-300 {
    color: $primary-button-300;
}

.primary-button-400 {
    color: $primary-button-400;
}

.primary-button-500 {
    color: $primary-button-500;
}

.primary-button-600 {
    color: $primary-button-600;
}

.primary-button-700 {
    color: $primary-button-700;
}

.primary-button-800 {
    color: $primary-button-800;
}

.primary-button-900 {
    color: $primary-button-900;
}

.secondary-button-050 {
    color: $secondary-button-050;
}

.secondary-button-100 {
    color: $secondary-button-100;
}

.secondary-button-200 {
    color: $secondary-button-200;
}

.secondary-button-300 {
    color: $secondary-button-300;
}

.secondary-button-400 {
    color: $secondary-button-400;
}

.secondary-button-500 {
    color: $secondary-button-500;
}

.secondary-button-600 {
    color: $secondary-button-600;
}

.secondary-button-700 {
    color: $secondary-button-700;
}

.secondary-button-800 {
    color: $secondary-button-800;
}

.secondary-button-900 {
    color: $secondary-button-900;
}

.error-050 {
    color: $error-050;
}

.error-100 {
    color: $error-100;
}

.error-200 {
    color: $error-200;
}

.error-300 {
    color: $error-300;
}

.error-400 {
    color: $error-400;
}

.error-500 {
    color: $error-500;
}

.error-600 {
    color: $error-600;
}

.error-700 {
    color: $error-700;
}

.error-800 {
    color: $error-800;
}

.error-900 {
    color: $error-900;
}

.information-050 {
    color: $information-050;
}

.information-100 {
    color: $information-100;
}

.information-200 {
    color: $information-200;
}

.information-300 {
    color: $information-300;
}

.information-400 {
    color: $information-400;
}

.information-500 {
    color: $information-500;
}

.information-600 {
    color: $information-600;
}

.information-700 {
    color: $information-700;
}

.information-800 {
    color: $information-800;
}

.information-900 {
    color: $information-900;
}

.order-color-warehouse {
    color: $order-color-warehouse;
}

.order-color-pickup {
    color: $order-color-pickup;
}

.order-color-delivery {
    color: $order-color-delivery;
}

.order-color-customer {
    color: $order-color-customer;
}

.order-color-notuse-1 {
    color: $order-color-notuse-1;
}

.order-color-notuse-2 {
    color: $order-color-notuse-2;
}

.mapping-color-1 {
    color: $mapping-color-1;
}

.mapping-color-2 {
    color: $mapping-color-2;
}

.mapping-color-3 {
    color: $mapping-color-3;
}

.mapping-color-4 {
    color: $mapping-color-4;
}

.mapping-color-5 {
    color: $mapping-color-5;
}

.primary-color-050 {
    color: $primary-color-050;
}

.primary-color-100 {
    color: $primary-color-100;
}

.primary-color-200 {
    color: $primary-color-200;
}

.primary-color-300 {
    color: $primary-color-300;
}

.primary-color-400 {
    color: $primary-color-400;
}

.primary-color-500 {
    color: $primary-color-500;
}

.primary-color-600 {
    color: $primary-color-600;
}

.primary-color-700 {
    color: $primary-color-700;
}

.primary-color-800 {
    color: $primary-color-800;
}

.primary-color-900 {
    color: $primary-color-900;
}

.secondary-color-050 {
    color: $secondary-color-050;
}

.secondary-color-100 {
    color: $secondary-color-100;
}

.secondary-color-200 {
    color: $secondary-color-200;
}

.secondary-color-300 {
    color: $secondary-color-300;
}

.secondary-color-400 {
    color: $secondary-color-400;
}

.secondary-color-500 {
    color: $secondary-color-500;
}

.secondary-color-600 {
    color: $secondary-color-600;
}

.secondary-color-700 {
    color: $secondary-color-700;
}

.secondary-color-800 {
    color: $secondary-color-800;
}

.secondary-color-900 {
    color: $secondary-color-900;
}

.color-white {
    color: $color-white;
    button {
        color: $color-white !important;
    }
}

.text-color-secondary {
    color: $text-color-secondary;
}

.grey-color {
    color: $grey-color;
}

.pms-color-moderate {
    color: $pms-color-moderate;
}

.delivering {
    background-color: #2196f3;
    color: white;
}

.collecting {
    background-color: #b754f5;
    color: white;
}

.collecting_delivering {
    color: white;
    background: linear-gradient(90deg, #b754f5, #2196f3);
}

.technical_action {
    background-color: black !important;
    color: white !important;
}

/** End Colors **/

html {
    height: 100vh;

    body {
        height: 100vh;
        margin: 0 !important;
    }
}

p-menu {
    .p-menu {
        border: none !important;
    }
}

.button-add {
    position: absolute;
    border-radius: 50%;
    right: 2%;
    bottom: 2%;
    width: 50px !important;
    height: 50px;
}

.p-dialog-content {
    height: 100%;
}

.leaflet-pm-toolbar .leaflet-pm-icon-drag {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJwZW4iIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1wZW4gZmEtdy0xNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0yOTAuNzQgOTMuMjRsMTI4LjAyIDEyOC4wMi0yNzcuOTkgMjc3Ljk5LTExNC4xNCAxMi42QzExLjM1IDUxMy41NC0xLjU2IDUwMC42Mi4xNCA0ODUuMzRsMTIuNy0xMTQuMjIgMjc3LjktMjc3Ljg4em0yMDcuMi0xOS4wNmwtNjAuMTEtNjAuMTFjLTE4Ljc1LTE4Ljc1LTQ5LjE2LTE4Ljc1LTY3LjkxIDBsLTU2LjU1IDU2LjU1IDEyOC4wMiAxMjguMDIgNTYuNTUtNTYuNTVjMTguNzUtMTguNzYgMTguNzUtNDkuMTYgMC02Ny45MXoiLz48L3N2Zz4=) !important;
}

* {
    font-family: BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

    .p-text-bold,
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: 600;
        color: #495057;
    }
}

.leaflet-top,
.leaflet-right {
    display: none;
}

.confirm_center-button .p-dialog-footer {
    display: flex;
    flex-flow: row-reverse;
    justify-content: center;

    button {
        margin-inline: 1rem;
    }
}

.action-button {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(2n) {
    background-color: #f7f7f7;
}

.underliner {
    text-decoration: none;
    color: #23547b;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.disabled-label {
    color: #49505773;
}

.p-datatable .p-datatable-loading-overlay {
    height: calc(100vh - 90px);
}

.card_max-height {
    transform: translateY(25px) !important;

    .p-orderlist-list {
        max-height: 90vh !important;
    }
}

.ellipsis-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.red-text {
    color: red;
}

.flex {
    display: flex;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.grid {
    display: grid;
}

.width-100 {
    width: 100%;
}

.width-75 {
    width: 75%;
}

.width-50 {
    width: 50%;
}

.width-30 {
    width: 30%;
}

.width-25 {
    width: 25%;
}

.width-20 {
    width: 20%;
}

.width-15 {
    width: 15%;
}

.width-10 {
    width: 10%;
}

.height-100 {
    height: 100%;
}

.table-container-large {
    //! USE CALC IS MANDATORY FOR P-TABLE TO MAKE IT RESPONSIVE
    height: calc(100vh - 105px);
}

.button-label-responsive {
    display: inline-block;
    margin-left: 5px;
}

.full-tooltip {
    max-width: 60%;
}

.no-header {
    .p-dialog-content {
        padding: 1rem 1.5rem !important;
    }

    .p-dialog-header {
        display: none !important;
    }
}

.low-alert {
    border-color: $primary-button-400 !important;

    .alert-status {
        background-color: $primary-button-400;
        color: $color-white;
    }
}

.moderate-alert {
    border-color: $pms-color-moderate !important;

    .alert-status {
        background-color: $pms-color-moderate;
        color: $color-white;
    }
}

.high-alert {
    border-color: $error-400 !important;

    .alert-status {
        background-color: $error-400 !important;
        color: $color-white !important;
    }
}

.anomaly-alert {
    border-color: $text-color-secondary !important;

    .alert-status {
        background-color: $text-color-secondary;
        color: $color-white;
    }
}

.uc-event {
    .alert-status {
        background-color: orange;
        color: white;
    }
}

.c-event {
    .alert-status {
        background-color: yellow;
        color: black;
    }
}

.out-of-phase-alert {
    background-color: #990099;
    color: #fff;
}

.spacing-right-1 {
    margin-right: 1rem;
}

.spacing-top-1 {
    margin-top: 1rem;
}

.fixed-modal-button {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 55px;
    background: white;
    gap: 1rem;
}

.overflow-value {
    max-width: 8rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.burger-button {
    button {
        width: 100%;
        text-align: left;
    }
}

.fixed-button {
    position: fixed;
    height: 60px;
    background: white;
    width: 100%;
    bottom: 0;
    left: 0;
}

.full-width {
    width: 100%;
    ul {
        width: 100%;
    }
}

.custom-scrollbar::-webkit-scrollbar {
    width: 20px;
    background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
}

.table-container-list-header {
    height: calc(100vh - 170px);
}

@media (max-width: 1400px) {
}

@media (max-width: 1400px) {
}

@media (max-width: 1400px) {
}

@media (max-width: 1200px) {
    .button-label-responsive {
        display: none;
    }
}

@media (max-width: 1024px) {
    .responsive-calendar {
        width: 6.5rem;
    }
}

/**************************************
 * Dropdown                           *
 **************************************/

.p-dropdown .p-dropdown-trigger {
    background: $primary-color-500;
    color: white;
    border-radius: 5px;
    border: 1px solid $primary-color-500;
}

.p-dropdown {
    background: #f9fcfe !important;
    border: 1px solid #dadada;
    border-radius: 5px;
    width: 100%;
}

.p-dropdown-label {
    padding-right: 0.5rem !important;
}

.p-dropdown .p-dropdown-label {
    box-sizing: border-box;
    padding: 6px 8px;
    gap: 8px;
    background: #f9fcfe;
    border-right: none;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333335 !important;
}

.p-inputtextarea,
.p-inputtext {
    box-sizing: border-box;
    padding: 6px 8px;
    gap: 8px;
    background: #f9fcfe;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333335;
}

.pointer {
    cursor: pointer;
}
.p-timeline .p-timeline-event-connector {
    background-color: #dee2e6 !important;
}

/**************************************
 * PMS                                *
 **************************************/

.pms-low-alert {
    background-color: $primary-button-400;
    color: $color-white;
}

.pms-moderate-alert {
    background-color: $pms-color-moderate;
    color: $color-white;
}

.pms-high-alert {
    background-color: $error-400 !important;
    color: $color-white !important;
}

.pms-anomaly-alert {
    background-color: $text-color-secondary;
    color: $color-white;
}

.p-dialog .p-dialog-content:last-of-type {
    border-radius: 3px;
}

.div-button-vehicle-health {
    button {
        width: 100% !important;
        margin: 0px !important;
        border: 0.5px solid #fff;
    }
}

.p-dialog-content:has(app-modal-history-table) {
    overflow: hidden !important;
}

/**************************************
 * PROVIDER                           *
 **************************************/

.modal-provider-edit-mt {
    min-height: 150px;
}

.inactive {
    background-color: #dadada;

    td {
        background-color: #dadada;
    }
}

.deactivate-alert {
    .alert-status {
        background-color: #dadada;
        color: #000 !important;
    }
}

.customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
    width: 99%;
    display: block;
    text-align: center;

    // maintenance request statuses

    &.status-funnel {
        background-color: #f59e42;
        color: #ffffff;
        padding: 0.5rem 3rem;
    }

    &.status-todo {
        background-color: #12bec9;
        color: #ffffff;
        padding: 0.5rem 3rem;
    }

    &.status-waiting {
        background-color: #f3bc23;
        color: #ffffff;
        text-align: center;
        padding: 0.5rem 3rem;
    }

    &.status-doing {
        background-color: #3864e7;
        color: #ffffff;
        padding: 0.5rem 3rem;
    }

    &.status-done {
        background-color: #1db241;
        color: #ffffff;
        padding: 0.5rem 3rem;
    }

    &.status-cancel {
        background-color: #fae4e4;
        border: 1px solid #d61a1a;
        color: #d61a1a;
        padding: 0.5rem 3rem;
    }

    // tour statuses

    &.status-NV {
        background-color: #feedaf;
        color: #8a5340;
    }

    &.status-VA {
        background-color: #eccfff;
        color: #694382;
    }

    &.status-ST {
        background-color: #b3e5fc;
        color: #23547b;
    }

    &.status-FI {
        background-color: #c8e6c9;
        color: #256029;
    }

    &.status-CA {
        background-color: #ffcdd2;
        color: #c63737;
    }

    // days
    &.day-monday {
        background-color: #ffffff;
        color: #414141;
    }
    &.day-tuesday {
        background-color: #ffcdd2;
        color: #c63737;
    }
    &.day-wednesday {
        background-color: #c0c0c0;
        color: #ffffff;
    }
    &.day-thursday {
        background-color: #b3e5fc;
        color: #23547b;
    }
    &.day-friday {
        background-color: #c8e6c9;
        color: #256029;
    }
    &.day-saturday {
        background-color: #414141;
        color: #ffffff;
    }
    &.day-sunday {
        background-color: #feedaf;
        color: #8a5340;
    }

    &.status-low_alert {
        background-color: $primary-button-400;
        color: $color-white;
    }

    &.status-moderate_alert {
        background-color: $pms-color-moderate;
        color: $color-white;
    }

    &.status-high_alert {
        background-color: $error-400 !important;
        color: $color-white !important;
    }

    &.status-anomaly {
        background-color: $text-color-secondary;
        color: $color-white;
    }
}

.link {
    cursor: pointer;
    color: #23547b;
}

.link:hover {
    text-decoration: underline;
}
